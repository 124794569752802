import React from "react"
import SvgLogoIcon from "../../../images/contract-completed.inline.svg"

const ContractCompletedIcon = ({ width = "131", height = "96", ...props }) => {
  return (
    <>
      <SvgLogoIcon width={width} height={height} {...props} />
    </>
  )
}

export default ContractCompletedIcon
