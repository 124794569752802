import { Button } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const StyledSubmitButton = styled(Button)`
  &.MuiButton-root {
    padding: 10px 12px !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 26px !important;
    min-height: auto !important;
  }  

  &.MuiButton-root.Mui-disabled {
    opacity: 0.5;
  }
`

function FormSubmitButton({loading, loadingText, ...props}) {
  return loading ? <StyledSubmitButton {...props} disabled={true}>{loadingText}</StyledSubmitButton>
    : <StyledSubmitButton {...props} />
}

export default FormSubmitButton