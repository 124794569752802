import React from 'react';
import SvgLogoIcon from '../../../images/completed_step.inline.svg';

const CompletedStepIcon = (props) => {
  return (
    <>
      <SvgLogoIcon {...props} />
    </>
  )
};

export default CompletedStepIcon;
