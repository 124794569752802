import { Box, Dialog, DialogContent, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import ITNImage from '../../../images/itn-tooltip-image.png';
import ITNImageSmall from '../../../images/itn-tooltip-image-sm.png';
import Example from '../../../images/measure-point-example.jpg';
import Image from '../image/Image';
import ModalTitle from '../modal/ModalTitle';

const StyledTooltipContent = styled.div`
  .image-list-wrapper {
    padding: 1rem;
    display: flex;
  }

  .itn-image-container {
    border: 2px solid #e0e0e0;
    border-radius: 4px;
    max-height: 100%;
  }

  ul {
    list-style-type: circle;
  }

  li::marker {
    color: #e6401d;
    font-size: 20px;
  }

  p {
    display: inline-flex;
    color: black;
  }

  .see-example-wrapper {
    display: flex;
    justify-content: center;
  }

  button {
    font-size: 20px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: transparent;
    border: 0;
    color: #e6401e;
    font-weight: 500;
  }

  button:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }
`;

const ITNTooltipContent = ({showExample, imgSrc, ...props}) => (
  <StyledTooltipContent>
    <Box
      className="image-list-wrapper"
      sx={{
        flexDirection: {
          xs: 'column',
          sm: 'row',
        }
      }}
    >
      <Box
        className="itn-image-container"
        sx={{
          width: {
            sm: '44rem',
          }
        }}
      >
        <Image src={imgSrc} />
      </Box>
      <ul>
        <li>
          <p>
            ИТН – Измервателна точка номер или Абонатен номер 
            е уникалното означение в съответната клиентска 
            информационна система, което идентифицира обекта 
            (точката на измерване)
          </p>
        </li>
        <li>
          <p>
            Намира се в блока с данни на клиента, до клиентския номер в
            месечната фактура.
          </p>
        </li>
      </ul>
    </Box>
    <div className="see-example-wrapper">
      <button onClick={showExample}>Виж пример</button>
    </div>
  </StyledTooltipContent>
);

const ITNTooltip = ({ children, ...tooltipProps }) => {
  const [showExmaple, setShowExample] = useState(false);

  const theme = useTheme();

  const isSmBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

  const componentsProps = {
    tooltip: {
      style: {
        backgroundColor: 'white',
        border: '1px solid #e6401e',
        borderTop: '3px solid #e6401e',
        boxShadow: '0px 3px 3.5px rgba(0, 0, 0, 0.4)',
        bordeRadius: '5px',
        maxWidth: !isSmBreakpoint && '38rem',
      },
    },
    arrow: {
      style: {
        color: theme.palette.secondary.main,
      },
    },
  };

  return (
    <>
      <Tooltip
        arrow
        componentsProps={componentsProps}
        title={<ITNTooltipContent imgSrc={isSmBreakpoint ? ITNImageSmall : ITNImage} showExample={() => setShowExample(true)} />}
        {...tooltipProps}
      >
        {children}
      </Tooltip>
  
      <Dialog
        sx={{ zIndex: 9000 }}
        open={showExmaple}
        onClick={() => setShowExample(false)}
        fullScreen={isSmBreakpoint}
      >
        <ModalTitle onClose={() => setShowExample(false)} />
        <DialogContent>
          <Image src={Example} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ITNTooltip;
