import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';

const StyledWrapper = styled('div')`
  .btn--secondary {
    border: none !important;
  }
`;

function AddPointButton({ prependPoint }) {
  return (
    <StyledWrapper>
      <button
        type="button"
        className="btn btn--secondary d-flex align-items-center"
        onClick={prependPoint}
      >
        <AddIcon style={{ marginBottom: 0, marginLeft: 0, marginRight: 6 }} />
        <div>Добави точка на измерване</div>
      </button>
    </StyledWrapper>
  );
}

AddPointButton.propTypes = {
  prependPoint: PropTypes.func.isRequired,
};

export default AddPointButton;
