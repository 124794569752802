import { fireGAEvent } from "../helpers/ga";

// const COMMERCIAL_REGISTER_URL = 'https://cr-app-w6zhsjlrmq-ew.a.run.app/commercial-register'; // old
const COMMERCIAL_REGISTER_URL = 'https://rpyb6xsvnw4fgyhyyqxt2cnuni0uwwuh.lambda-url.eu-west-1.on.aws';
const UIC_QUERY_PARAM = 'UIC';

export async function getCompany(eik) {
  //
  //https://api.github.com/repos/gatsbyjs/gatsby

  if (!eik) {
    return null;
  }

  fireGAEvent("user_wrote_uic", {
    uic: eik,
  });

  const url = new URL(COMMERCIAL_REGISTER_URL);

  const searchParams = {
    [UIC_QUERY_PARAM]: eik,
  };

  for (const key in searchParams) {
    url.searchParams.append(key, searchParams[key]);
  }

  const result = await fetch(url.href);

  if (result) {
    const data = await result.json();

    fireGAEvent("respond_with_uic_data", {
      ...data
    });

    return data;
  }

  // return fetch(
  //   `https://x1rjv9hn5l.execute-api.eu-west-1.amazonaws.com/companies/BG` + eik
  // ).then(data => data.json())
}

export function validateEIK(eik) {
  return eik.length === 9
}
