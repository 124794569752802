import React, { useState } from 'react';
import OfferPreview from './OfferPreview';
import ContractPartOne from '../../../../images/Contract_part1.png';
import BorderlessButton from '../../../atoms/buttons/BorderlessButton';
import SignTypeForm from './SignTypeForm';
import BackButton from '../BackButton';
import FormSubmitButton from '../FormSubmitButton';
import { useTheme } from '@mui/material';
import { signOfferValidationSchema } from '../validation/schemas';
import ShowOfferModal from '../../../organisms/show-offer-modal/ShowOfferModal';
import ShowPdfOfferModal from '../../../organisms/show-offer-modal/ShowPdfOfferModal';
import OfferDocumentPreview from '../../../organisms/show-offer-modal/OfferDocumentPreview';
import { fireGAEvent } from "../../../../helpers/ga";

function OfferForm({
  initialValues: { sign, ...rest },
  onSave,
  back,
  next,
  currentStep,
  loading,
  fileName,
  logo,
  contract,
}) {
  const [showFullOffer, setShowFullOffer] = useState(false);

  const theme = useTheme();

  const showHideOffer = () => {
    setShowFullOffer((prev) => !prev);
  };

  const handleSave = (e, value) => {
    onSave({ sign: value }, currentStep)
  }

  const handleSubmit = async () => {
    const parsed = { sign };
    try {
      const isValid = await signOfferValidationSchema.validate(parsed);

      fireGAEvent("sign_contract_request_contract", {
        ...parsed,
      });

      next();
    } catch (error) {
      console.log(error);
    }
  }

  // Used to cut document for partial preview
  const setDocumentStyles = (documentRef) => {
    documentRef.current.style.height = '20rem';
    documentRef.current.style.overflow = 'hidden';
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <div style={{ padding: '2.4rem 2.4rem 0 2.4rem' }}>
          <h3 className="mb-020">Договор</h3>

          <p className="small-text">Прегледайте данните на вашия договор</p>
        </div>

        {/* Uncomment to show html contracts
        ***********************************
        {!showFullOffer ? <OfferPreview
          showFullOffer={false}
          data={rest}
          fileName={fileName}
          logo={logo}
        />
          :
        <ShowOfferModal
          open={showFullOffer}
          closeModal={() => setShowFullOffer(false)}
          maxWidth="70rem"
          offerPreviewProps={{
            data: rest,
            fileName: fileName,
            showFullOffer: true,
            wrapperStyle:{
              flexWrap: 'nowrap',
              height: '100%',
            },
            logo,
          }}
        />} */}

        <OfferDocumentPreview
          contract={contract}
          onLoadSuccess={setDocumentStyles}
          documentProps={{
            loading: 'Зареждане на договор...',
            noData: 'Няма качен договор',
            error: 'Получи се грешка',
          }}
          pageRenderOptions={{
            className: 'd-flex justify-content-center',
            scale: '1.5',
          }}
        />

        {showFullOffer && 
          <ShowPdfOfferModal
            open={showFullOffer}
            closeModal={() => setShowFullOffer(false)}
            maxWidth="70rem"
            contract={contract}
            documentProps={{
              loading: 'Зареждане на договор...',
              noData: 'Няма качен договор',
              error: 'Получи се грешка',
            }}
            pageRenderOptions={{
              className: 'd-flex justify-content-center',
              scale: '1.8',
            }}
          />
        }

        <div style={{ padding: '0 2.4rem' }}>
          <BorderlessButton style={{ padding: 0 }} onClick={showHideOffer}>
            {showFullOffer ? 'Скрий договор' : 'Прегледай договор'}
          </BorderlessButton>
        </div>

        <SignTypeForm onChange={handleSave} initialValues={{ sign }} style={{ margin: '0 2.4rem' }} />

        <p style={{ padding: '1rem 2.4rem 0 2.4rem' }}>
          С натискане на бутона “Заяви договор” се съгласявам с обработката на
          моите лични данни и приемам Общите условия за ползване на сайта.
        </p>

        <hr />

        <div
          className="row justify-content-end"
          style={{ padding: '0 2.4rem 2.4rem 2.4rem' }}
        >
          <div className="col-md-3 d-flex justify-content-end">
            <BackButton
              variant="outlined"
              style={{
                color: theme.palette.secondary.main,
                borderColor: theme.palette.secondary.main,
              }}
              onClick={() => back()}
            >
              Назад
            </BackButton>
          </div>
          <div className="col-md-4">
            <FormSubmitButton
              style={{
                backgroundColor: theme.palette.secondary.main,
              }}
              onClick={() => handleSubmit()}
              disabled={!sign}
              loading={loading}
              loadingText="Заяви договор..."
            >
              Заяви договор
            </FormSubmitButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfferForm;
