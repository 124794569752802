import { Box, styled } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import MeasurePointsForm from "./form-components/measurePoints/MeasurePointsForm";
import OfferForm from "./form-components/offer/OfferForm";
import PrivateDataForm from "./form-components/privateData/PrivateDataForm";
import { fullOfferValidationSchema } from "./form-components/validation/schemas";
import ContractCompleteModal from "./modal/ContractCompleteModal";
import SignOfferStepper from "./stepper/SignOfferStepper";
import jsPDF from "jspdf";
import { Typography } from "@mui/material";

const FormWrapper = styled(Box)({
  border: "1px solid #F3F3F3",
  backgroundColor: "white",
});

const steps = [
  {
    label: "Данни за фирмата",
    component: PrivateDataForm,
  },
  {
    label: "Данни за обекта",
    component: MeasurePointsForm,
  },
  {
    label: "Заявка за договор",
    component: OfferForm,
  },
];

const setInitialValues = (operator) => ({
  eik: "",
  company: "",
  names: "",
  email: "",
  otherEmail: "",
  phone: "",
  city: "",
  zip: "",
  street: "",
  sign: "electronic",
  points: [
    {
      city: "",
      zip: "",
      street: "",
      itn: "",
      file: "",
      fileName: "",
      operator: operator || "cez",
      check: true,
    },
  ],
});

function SignOfferForm({
  operator,
  fileName,
  logo,
  contract,
  distributor,
  offerName,
  videoGuideLink,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(
    steps.map((s, i) => ({ completed: false }))
  );
  const [formValues, setFormValues] = useState(setInitialValues(operator));
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleSave = (values, step) => {
    setFormValues((prev) => ({ ...prev, ...values }));
    setCompletedSteps((prev) => {
      prev[step] = { completed: true };
      return prev;
    });
  };

  const submitForm = async () => {
    setLoading(true);
    try {
      const isValid = await fullOfferValidationSchema.isValid(formValues);

      if (!isValid) {
        throw new Error("Form data is invalid");
      }

      const form = {
        ...formValues,
        points: formValues.points.map((p) =>
          p.check
            ? {
                zip: formValues.zip,
                city: formValues.city,
                street: formValues.street,
                operator: p.operator,
                itn: p.itn,
              }
            : {
                zip: p.zip,
                city: p.city,
                street: p.street,
                operator: p.operator,
                itn: p.itn,
              }
        ),
        signOffer: true,
        files: [],
        distributor: distributor.title,
        offerName: offerName,
      };

      const files = [];

      formValues.points.forEach((point) => {
        if (point.file) {
          files.push({
            fileName: point.fileName,
            fileType: point.file.type,
            file: point.file,
          });
        }
      });

      const uploadFilesAndCreateLead = async () => {
        if (files.length > 0) {
          const getPresignedUrlsResult = await fetch(
            "https://x1rjv9hn5l.execute-api.eu-west-1.amazonaws.com/upload-multiple-files",
            {
              method: "POST",
              body: JSON.stringify({ files: files }),
            }
          );

          const { fileUploadUrls } = await getPresignedUrlsResult.json();

          for (let i = 0; i < files.length; i++) {
            const { file, fileType, fileName } = files[i];
            const { fileKey, url } = fileUploadUrls[i];

            const uploadResult = await fetch(url, {
              method: "PUT",
              body: file,
              headers: {
                "Content-Type": fileType,
              },
            });

            if (i < form.points.length) {
              form.points[i].fileKey = fileKey;
            }

            form.files.push(fileKey);
          }
        }

        const toSend = {
          form,
          page: window.location.href,
        };

        const sendEmailAndCreateLead = await fetch(
          "https://x1rjv9hn5l.execute-api.eu-west-1.amazonaws.com/contract",
          {
            method: "POST",
            body: JSON.stringify(toSend),
            headers: {
              "Content-Type": "application/json",
            },
          }
        ).then((response) => response.json());

        if (sendEmailAndCreateLead) {
          console.log("Success:", sendEmailAndCreateLead);
          setLoading(false);
          setShowModal(true);
        }
      };

      // Call this instead of uploadFilesAndCreateLead to generate pdf and upload the rest
      // const generatePdf = async () => {
      //   const contractElement = document.querySelector("#htmlPdfContract");
      //   const contractElementCopy = contractElement.cloneNode(true);

      //   const seperatePageItems = [...contractElementCopy.querySelectorAll('.new-pdf-page')];

      //   const doc = new jsPDF("portrait", "pt", [1350, 1400]);

      //   const initialPageHeight = doc.internal.pageSize.getHeight();

      //   const addPagesAndUpload = async (pdfDoc, htmlPageElements, isFirstPage) => {
      //     if (!htmlPageElements || htmlPageElements.length === 0) {
      //       const pdfBlob = pdfDoc.output("blob");
      //       var fileFromBlob = new File([pdfBlob], "contract.pdf", {
      //         type: "application/pdf",
      //         lastModified: new Date().getTime(),
      //       });
      //       files.push({
      //         file: fileFromBlob,
      //         fileName: "contract.pdf",
      //         fileType: "application/pdf",
      //       });

      //       if (files.length > 0) {
      //         const getPresignedUrlsResult = await fetch(
      //           "https://x1rjv9hn5l.execute-api.eu-west-1.amazonaws.com/upload-multiple-files",
      //           {
      //             method: "POST",
      //             body: JSON.stringify({ files: files }),
      //           }
      //         );

      //         const { fileUploadUrls } = await getPresignedUrlsResult.json();

      //         for (let i = 0; i < files.length; i++) {
      //           const { file, fileType, fileName } = files[i];
      //           const { fileKey, url } = fileUploadUrls[i];

      //           const uploadResult = await fetch(url, {
      //             method: "PUT",
      //             body: file,
      //             headers: {
      //               "Content-Type": fileType,
      //             },
      //           });

      //           if (i < form.points.length) {
      //             form.points[i].fileKey = fileKey;
      //           }

      //           form.files.push(fileKey);
      //         }
      //       }

      //       const toSend = {
      //         form,
      //         page: window.location.href,
      //       };

      //       const sendEmailAndCreateLead = await fetch(
      //         "https://x1rjv9hn5l.execute-api.eu-west-1.amazonaws.com/contract",
      //         {
      //           method: "POST",
      //           body: JSON.stringify(toSend),
      //           headers: {
      //             "Content-Type": "application/json",
      //           },
      //         }
      //       ).then((response) => response.json());

      //       if (sendEmailAndCreateLead) {
      //         console.log("Success:", sendEmailAndCreateLead);
      //         setLoading(false);
      //         setShowModal(true);
      //       }
      //       return;
      //     }

      //     const pageCount = pdfDoc.getNumberOfPages();

      //     console.log('pageCount', pageCount);

      //     let pageStartY = 0;

      //     const margin = [100, 70, 100, 70];

      //     if (!isFirstPage) {
      //       pageStartY = pageCount * (initialPageHeight - 200);
      //       pdfDoc.addPage();
      //     }

      //     const pageHtmlElement = htmlPageElements.shift();

      //     pageHtmlElement.style.minWidth = "1200px";

      //     pdfDoc.html(pageHtmlElement, {
      //       x: 0,
      //       y: pageStartY,
      //       autoPaging: "text",
      //       margin,
      //       html2canvas: {
      //         scale: 1,
      //         letterRendering: true,
      //         allowTaint: true,
      //       },
      //       callback: async function (pdf) {
      //         await addPagesAndUpload(pdf, htmlPageElements, false)
      //       },
      //     });
      //   }

      //   await addPagesAndUpload(doc, seperatePageItems, true);
      // };

      // await generatePdf();
      await uploadFilesAndCreateLead();
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("Получи се грешка. Моля, опитайте по-късно");
    }
  };

  const next = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep === steps.length - 1 ? prevActiveStep : prevActiveStep + 1
    );
  };

  const back = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep === 0 ? prevActiveStep : prevActiveStep - 1
    );
  };

  const handleStep = (stepIndex) => {
    if (stepIndex >= steps.length || stepIndex < 0) {
      return;
    }

    setActiveStep(stepIndex);
  };

  const renderForm = useCallback(() => {
    const FormComponent = steps[activeStep].component;

    return (
      <FormComponent
        initialValues={formValues}
        next={activeStep === steps.length - 1 ? submitForm : next}
        back={back}
        onSave={handleSave}
        currentStep={activeStep}
        loading={loading}
        logo={logo}
        contract={contract}
        currentOperator={operator || "cez"}
      />
    );
  }, [activeStep, formValues, formValues.points, loading, fileName]);

  return (
    <Box
      className="d-flex"
      sx={{
        justifyContent: {
          xs: "center",
          sm: "space-between",
        },
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        flexWrap: "wrap",
        cursor: loading && "wait",
      }}>
      <SignOfferStepper
        active={activeStep}
        steps={steps}
        completedSteps={completedSteps}
        handleStep={handleStep}
        disabled={loading}
      />

      <FormWrapper
        sx={{
          maxWidth: { xs: "100%", sm: "53rem" },
          width: { xs: "100%", sm: "70%" },
        }}>
        {renderForm()}
      </FormWrapper>

      {videoGuideLink && (
        <Typography
          sx={{
            py: 2,
            textAlign: "right",
            marginLeft: "auto",
            maxWidth: { xs: "100%", sm: "53rem" },
            width: { xs: "100%", sm: "70%" },
          }}>
          Изпитвате затруднения? Може би нашият{" "}
          <a href={videoGuideLink}>видео урок</a> ще ви помогне.{" "}
        </Typography>
      )}

      <ContractCompleteModal
        open={showModal}
        redirect={(e) => {
          e.preventDefault();
          window.location.href = "/";
        }}
      />
    </Box>
  );
}

SignOfferForm.propTypes = {
  operator: PropTypes.string,
  fileName: PropTypes.string,
};

export default SignOfferForm;
