import * as yup from 'yup';
import { validateEIK } from '../../../../helpers/getEIK';

yup.addMethod(yup.string, 'eikNumber', function (errorMessage) {
  return this.test(`test-eik-number`, errorMessage, function (value) {
    const { path, createError } = this;

    return (
      (value !== undefined && validateEIK(value)) ||
      createError({ path, message: errorMessage })
    );
  });
});

yup.addMethod(yup.string, 'phone', function(errorMessage) {
  const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  return this.matches(phoneRegex, errorMessage);
});

export const privateDataValidationSchema = yup.object().shape({
  eik: yup
    .string()
    .eikNumber('ЕИК не е валиден.')
    .required('Задължително поле.'),
  company: yup.string().required('Задължително поле.'),
  email: yup
    .string()
    .email('Въведете валиден email адрес.')
    .required('Задължително поле.'),
  otherEmail: yup
    .string()
    .email('Въведете валиден email адрес.')
    .optional(),
  names: yup.string().required('Задължително поле.'),
  phone: yup.string().optional().phone('Въведете валиден телефон.'),
  city: yup.string().required('Задължително поле.'),
  zip: yup.string().required('Задължително поле.'),
  street: yup.string().required('Задължително поле.'),
});

const addressCheckCondition = yup.string().when('check', {
  is: true,
  then: yup.string().optional(),
  otherwise: yup.string().required('Задължително поле.'),
});

export const measurePointsValidationSchema = yup.object().shape({
  points: yup
    .array()
    .min(1, 'Поне една точка за измерване е задължителна')
    .of(
      yup.object().shape(
        {
          check: yup.bool().optional(),
          city: addressCheckCondition,
          zip: addressCheckCondition,
          street: addressCheckCondition,
          itn: yup.string(),
          operator: yup.string().required('Задължително поле.'),
          file: yup.string(),
        }
      )
    ),
});

export const signOfferValidationSchema = yup.object().shape({
  sign: yup.string().required('Задължително поле.'),
});

export const fullOfferValidationSchema = yup.object().shape({
  eik: yup
    .string()
    .eikNumber('ЕИК не е валиден.')
    .required('Задължително поле.'),
  company: yup.string().required('Задължително поле.'),
  email: yup
    .string()
    .email('Въведете валиден email адрес.')
    .required('Задължително поле.'),
  otherEmail: yup
    .string()
    .email('Въведете валиден email адрес.')
    .optional(),
  names: yup.string().required('Задължително поле.'),
  phone: yup.string().optional(),
  city: yup.string().required('Задължително поле.'),
  zip: yup.string().required('Задължително поле.'),
  street: yup.string().required('Задължително поле.'),
  points: yup
    .array()
    .min(1, 'Поне една точка за измерване е задължителна')
    .of(
      yup.object().shape(
        {
          check: yup.bool().optional(),
          city: addressCheckCondition,
          zip: addressCheckCondition,
          street: addressCheckCondition,
          itn: yup.string(),
          operator: yup.string().required('Задължително поле.'),
          file: yup.string(),
        }
      )
    ),
  sign: yup.string().required('Задължително поле.'),
});
