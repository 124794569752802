import { Button, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

// Using !important, since they are overriden in style files
const StyledBackButton = styled(Button)`
  &.MuiButton-root {
    padding: 10px 12px !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 26px !important;
    min-height: auto !important;
    min-width: auto !important;
    width: auto !important;
    color: rgb(230, 64, 30) !important;
  }  

  &.MuiButton-root.Mui-disabled {
    opacity: 0.5;
  }
`;

const StyledBackButtonExtended = styled(StyledBackButton)`
  &.MuiButton-root {
    width: 100% !important;
  }  
`;

function BackButton(props) {
  const theme = useTheme();
  const isBelowLgBreakpoint = useMediaQuery(theme.breakpoints.down('lg'));

  // TODO:
  // - remove !important from style files for .root MUI classes to avoid this
  return isBelowLgBreakpoint ? <StyledBackButtonExtended {...props} /> : <StyledBackButton {...props} />
}

export default BackButton