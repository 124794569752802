import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledCheckbox = styled(Checkbox)`
  .MuiSvgIcon-root {
    color: #e6401e;
    margin-right: 0.3rem;
  }
`;

function AddressCheckbox({
  pointData,
  index,
  pointTouched,
  pointErrors,
  handleChange,
  setFieldValue,
  resetPointAddress
}) {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <FormControlLabel
            checked={pointData.check}
            onChange={(e, checked) => {
              if (checked) {
                resetPointAddress();
              }
              setFieldValue(`points.${index}.check`, checked)
            }}
            control={<StyledCheckbox />}
            label="Адресът на обекта е същият като адреса на управление"
          />
        </div>
      </div>

      {!pointData.check && (
        <>
          <div className="row">
            <div className="col-md-6">
              <TextField
                name={`points.${index}.city`}
                label="Населено място"
                variant="outlined"
                style={{ margin: 8 }}
                onChange={handleChange}
                value={pointData.city}
                error={pointTouched.city && Boolean(pointErrors.city)}
                helperText={pointTouched.city && pointErrors.city}
              />
            </div>
            <div className="col-md-6">
              <TextField
                name={`points.${index}.zip`}
                label="Пощенски код"
                variant="outlined"
                style={{ margin: 8 }}
                value={pointData.zip}
                onChange={(e) => {
                  if (!Number(e.target.value) && e.target.value != 0) {
                    return;
                  }

                  handleChange(e);
                }}
                error={pointTouched.zip && Boolean(pointErrors.zip)}
                helperText={pointTouched.zip && pointErrors.zip}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <TextField
                name={`points.${index}.street`}
                label="Улица с номер и вход"
                variant="outlined"
                style={{ margin: 8 }}
                value={pointData.street}
                onChange={handleChange}
                error={pointTouched.street && Boolean(pointErrors.street)}
                helperText={pointTouched.street && pointErrors.street}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

AddressCheckbox.propTypes = {
    index: PropTypes.number.isRequired,
    pointData: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    pointTouched: PropTypes.object.isRequired,
    pointErrors: PropTypes.object.isRequired,
}

export default AddressCheckbox
