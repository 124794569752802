import React, { useCallback } from 'react';
import { styled } from '@mui/material';
import dayjs from 'dayjs';
import { getFormattedData } from './utils/helpers';
import { OPERATOR_OPTIONS_MAP } from '../../../../../helpers/constants';

// Борсова цена +фиксирана надбавка elca.bg exclusive

const StyledWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
`;

const StyledPageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  font-family: 'Roboto-Regular';
  letter-spacing: 1px;
  padding-right: 3rem;
  padding-left: 3rem;
  
  span {
   all: inherit !important;
  }
  
  p, ol {
   font-size: 22px !important;
   line-height: 22px !important;
   color: black;
  }
  
  b {
   font-size: 24px;
  }
  
  ol {
   padding-left: 15px;
  }

  ol > li {
   padding-left: 20px;
  }
  
  .list-with-no-style > li {
   list-style: none;
  }
`;

const StyledDataElement = styled('strong')`
  border-bottom: 1px dotted black;
  white-space: pre;
`;

const StyledDocumentPage = styled('div')`
  padding: 2rem;
  background-color: white;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
  position: relative;
`;

const StyledDocumentTitle = styled('b')`
  display: block;
  width: 100%;
  text-align: center;
`;

const StyledColumn = styled('td')`
  width: 50%;
  border: none;
`;

const StyledDiagonalText = styled('span')`
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  transform-origin: top left;
  background-color: transparent;
  color: rgb(0 0 0 / 9%);
  padding: 10px;
  font-size: 180px; 
`;

const StyledPageNumber = styled('h6')`
  text-align: end;
`;

const offerHtml = `
{LOGO_HEADER}
<p class="s1" style="padding-top: 2pt;text-indent: 0pt;text-align: center;">ДОГОВОР</p>
<p style="text-indent: 0pt;text-align: center;">за комбинирани услуги</p>
<p class="s2" style="text-indent: 0pt;text-align: center;">№ ТЕ……/{DATE}г.</p>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<p class="s2" style="padding-top: 3pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Днес, {DATE}г., между:</p>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<p style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">„{COMPANY}", регистрирано в Търговския регистър към Агенция по вписванията с ЕИК {EIK},  ИН  по  ЗДДС:  BG  ……………,  със  седалище  и  адрес  на  управление: {ADDRESS}, представлявано от {NAME} - Управител, наричано по-долу за краткост КЛИЕНТ,</p>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<p class="s2" style="padding-left: 41pt;text-indent: 0pt;text-align: left;">и</p>
<p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">„ЕНЕРГЕО“ ЕООД, регистрирано в Търговския регистър към Агенция по вписванията с ЕИК 131346040, ИН по ЗДДС: BG131346040, със седалище и адрес на управление: гр. София 1588, бул. „Околовръстен път“ № 459, регистрирано на пазара на балансираща енергия като ТЪРГОВЕЦ  на  електрическа  енергия  и  координатор  на  балансираща  група  с идентификационен номер на търговски участник 32X0011001004024, представлявано от Константин Симеонов Делисивков – Управител, наричано по-долу за краткост ТЪРГОВЕЦ,</p>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<p class="s2" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">заедно наричани СТРАНИТЕ</p>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">на основание чл. 100, ]ал. 1 от Закона за енергетиката (ЗЕ) и по смисъла на чл. 11, т. 10 от Правилата за търговия с електрическа енергия (ПТЕЕ), се сключи настоящият договор за следното:</p>
<p style="text-indent: 0pt;text-align: left;"><br></p>
      <p class="s5" style="text-align: center;">I. ПРЕДМЕТ НА ДОГОВОРА</p>
      <p class="s2" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 1. (1) ТЪРГОВЕЦЪТ извършва смяна на доставчика на електрическа енергия на КЛИЕНТА на пазара на електрическа енергия по свободно договорени цени и предоставя на КЛИЕНТА комбинирани услуги по смисъла на чл. 20 от ПТЕЕ, а КЛИЕНТЪТ получава и заплаща предоставените услуги в срокове и при условията, описани в настоящия договор.</p>
            <p style="padding-left: 21pt;text-indent: -15pt;line-height: 14pt;text-align: justify;">(2) Услугите в изпълнение на горната алинея включват:</p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">1. извършване на процедура по смяна на доставчика на електрическа енергия на пазара по свободно договорени цени и на координатора на балансираща група с включване на КЛИЕНТА като член на стандартната балансираща група на ТЪРГОВЕЦА;</p>
            <p style="padding-left: 17pt;text-indent: -11pt;text-align: justify;">2. доставка на електрическа енергия съгласно индивидуалната консумация на КЛИЕНТА, за която ТЪРГОВЕЦЪТ изготвя почасови прогнози за потреблението на обектите на КЛИЕНТА;</p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">3. фактуриране  на  сумите  за  мрежови  услуги,  предоставени  от  съответното разпределително и/или преносното дружество, определени с решение на Комисията за енергийно и водно регулиране;</p>
            <p style="padding-left: 17pt;text-indent: -11pt;text-align: justify;">4. поемане на всички разходи за постигнатите небаланси.</p>
            <p style="padding-left: 22pt;text-indent: -16pt;text-align: justify;">(3) Услугите по смисъла на ал. 1 и ал. 2 ще бъдат предоставяни за обектите на КЛИЕНТА, посочени в Приложение 1, неразделна част от този договор.</p>
            <p class="s2" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">(3.1) СТРАНИТЕ се съгласяват, че КЛИЕНТЪТ може да изпраща нови обекти за добавяне или извеждане от балансиращата група на ТЪРГОВЕЦА, посочени в Приложение 1 от Договора, за което не се изисква сключване на Допълнително споразумение.</p>
            <p style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">(3.2) КЛИЕНТЪТ следва да изпрати данни за обектите по електронна поща: ИТН, клиентски номер, адрес, наименование на обекта и други данни, които са посочени във фактура за доставка на електрическа енергия.</p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">(3.3) Обектът се добавя към балансиращата група на ТЪРГОВЕЦА до 10-то число на месеца, предхождащ месеца на първоначална доставка за този обект.</p>
            <p style="padding-left: 5pt;text-indent: 0pt;line-height: 14pt;text-align: justify;">(3.4) Обектът се извежда от балансиращата група на ТЪРГОВЕЦА до 10-то число на последния месец на доставка за този обект.</p>
            <p class="s2" style="padding-left: 5pt;text-indent: 0pt;line-height: 107%;text-align: justify;">(4) С подписването на настоящия Договор КЛИЕНТЪТ, упълномощава ТЪРГОВЕЦА да сключи от негово име и за негова сметка договор за достъп до и пренос с оператора на съответното мрежово дружество, както и да извърши всички необходими действия съгласно ПТЕЕ и останалата нормативна рамка, за регистрация на обектите на свободен пазар.</p>
      <p style="text-indent: 0pt;text-align: left;"><br></p>
      <p class="s5" style="text-align: center;">II. СРОК И ПРЕКРАТЯВАНЕ НА ДОГОВОРА</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 2. (1) Този Договор влиза в сила от датата на подписването му и е със срок на действие съгласно ал. 2 по-долу.</p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">(2) Задълженията  за  доставка  на  активна  електрическа  енергия  на  КЛИЕНТА  и отговорността за балансиране възникват в 00:00 ч. на датата, съгласно известието от съответното разпределително дружество, че смяната на доставчика и на координатора по чл. 1, ал. 2, т. 1 по-горе е извършена и КЛИЕНТЪТ е включен като член на балансиращата група, чийто координатор е ТЪРГОВЕЦЪТ и са с продължителност 12 месеца от тази дата.</p>
            <p style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">(3) За всички обекти на КЛИЕНТА, посочени в Приложение 1, срокът по ал. 2 се брои от регистрацията  на  обекта/тите  на  пазара  на  електрическа  енергия  по  свободно договорени цени, съгласно известието от съответното разпределително дружество, която е извършена най-рано във времето.</p>
            <p style="padding-top: 8pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">(4) След изтичане на срока по ал. 2, действието на договора се удължава автоматично за неопределен  срок,  ако  нито  една  от  страните  не  уведоми  другата  писмено  за прекратяването му по реда на чл. 3, ал. 1, т. 2 по-долу.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 3. (1) Договорът може да бъде прекратен предсрочно в следните случаи:</p>
            <p class="s2" style="padding-left: 16pt;text-indent: -11pt;text-align: justify;">1. по взаимно писмено съгласие между СТРАНИТЕ;</p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">2. едностранно, по искане на всяка от СТРАНИТЕ, с отправяне до другата страна на предварително писмено предизвестие със срок не по-малък от един месец от датата на получаване на предизвестието от другата страна. Прекратяването се счита винаги от първо число на месеца, следващ месеца, през който предизвестието изтича;</p>
            <p style="padding-left: 17pt;text-indent: -11pt;line-height: 14pt;text-align: justify;">3. едностранно от изправната страна със седемдневно писмено предизвестие в случай на неизпълнение на задълженията по договора на другата страна;</p>
            <p class="s2" style="padding-left: 29pt;text-indent: -23pt;text-align: justify;">4. едностранно от ТЪРГОВЕЦА с писмено уведомление при промяна на собственика/ползвателя/наемателя на обект/ите от Приложение № 1;</p>
            <p style="padding-left: 17pt;text-indent: -11pt;text-align: left;">5. при отстраняване от пазара на балансираща енергия на някоя от СТРАНИТЕ;</p>
            <p class="s2" style="padding-left: 16pt;text-indent: -11pt;text-align: left;">6. при неподновяване или отнемане на лицензията на ТЪРГОВЕЦА;</p>
            <p style="padding-left: 20pt;text-indent: -15pt;text-align: left;">7. при откриване на производство по несъстоятелност или ликвидация на някоя от СТРАНИТЕ;</p>
            <p style="padding-left: 17pt;text-indent: -11pt;text-align: justify;">8. при настъпване на непреодолима сила, която забавя изпълнението на договора с повече от 30 (тридесет) дни.</p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">(2) Във всички случаи по предходната алинея двете СТРАНИ в двудневен срок от датата на прекратяване на Договора съгласно предходната алинея подписват двустранен протокол, отчитащ изпълнението на Договора до момента на неговото прекратяване и уреждащ финансовите им взаимоотношения. Проектът за такъв протокол се предлага от ТЪРГОВЕЦА в срок от един работен ден от датата на прекратяване на Договора.</p>
            <p class="s2" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">(3) В случай на прекратяване на договора ТЪРГОВЕЦЪТ има право да предприеме действия по изключване на КЛИЕНТА от стандартната балансираща група на ТЪРГОВЕЦА.</p>
      <p style="text-indent: 0pt;text-align: left;"><br></p>
      <p class="s5" style="text-align: center;">III. ПРАВА И ЗАДЪЛЖЕНИЯ НА СТРАНИТЕ</p>
      <p class="s2" style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Чл. 4. (1) ТЪРГОВЕЦЪТ се задължава да:</p>
            <p style="padding-left: 20pt;text-indent: -14pt;text-align: left;">1. извърши необходимите правни и фактически действия, с които да регистрира КЛИЕНТА на пазара на електрическа енергия по свободно договорени цени;</p>
            <p style="padding-left: 20pt;text-indent: -14pt;text-align: left;">2. обезпечи продажбата на електрическа енергия в местата на доставка при обектите на КЛИЕНТА;</p>
            <p class="s8" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">3. изготвя дневни графици за доставка съгласно всички изисквания, срокове и условия определени в ПТЕЕ, както и други документи свързани с покупко-продажбата на електрическа енергия за обектите на КЛИЕНТА; </p>
            <p style="padding-left: 20pt;text-indent: -14pt;line-height: 14pt;text-align: justify;">4. поеме възникналите разходи за балансираща енергия за обектите на КЛИЕНТА;</p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">5. фактурира сумите за мрежови услуги, предоставени от съответното разпределително и/или преносното дружество, определени с решение на Комисията за енергийно и водно  регулиране;</p>
            <p style="padding-left: 20pt;text-indent: -14pt;line-height: 14pt;text-align: justify;">6. предоставя информация и документи във връзка с изпълнението на този договор при писменото им поискване от страна на КЛИЕНТА.</p>
      <p class="s2" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">(2) ТЪРГОВЕЦЪТ има право да:</p>
            <p class="s2" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">1. получи  от  КЛИЕНТА  пълномощно,  заверено  по  надлежния  нотариален  ред  да  го представлява пред компетентните органи, както и да изисква и получава други документи, свързани с регистрацията по т. 1 от ал. 1 на настоящия член. </p>
            <p style="padding-left: 19pt;text-indent: -14pt;line-height: 14pt;text-align: justify;">2. изисква и получава информация относно прогнозните режими на работа и планови прекъсвания за обектите на КЛИЕНТА;</p>
            <p style="padding-left: 19pt;text-indent: -14pt;text-align: justify;">3. получи възнаграждение за предоставените по чл. 1, ал. 2 по-горе услуги съгласно количествата електрическа енергия, измерени от съответното мрежово дружество;</p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">4. започне процедура по временно преустановяване на снабдяването с електрическа енергия, в случай че КЛИЕНТЪТ забави плащания по настоящия договор с повече от 10 (десет) дни и съгласно условията по този договор.</p>
      <p class="s2" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 5. (1) КЛИЕНТЪТ се задължава да:</p>
            <p style="padding-left: 20pt;text-indent: -14pt;text-align: justify;">1. съдейства на ТЪРГОВЕЦА при изпълнението на задължението му по чл. 4, ал. 1, т. 1 като за целта му предостави пълномощно, заверено по надлежния нотариален ред, да го  представлява пред компетентните органи, както и да му предоставя своевременно други документи свързани с регистрацията; </p>
            <p style="padding-left: 20pt;text-indent: -14pt;text-align: justify;">2. заплаща възнаграждение за ползваните по чл. 1, ал. 2 по-горе услуги съгласно количествата електрическа енергия, измерени от съответното разпределително или преносно дружество и цената, определена по чл. 7 по-долу;</p>
            <p class="s2" style="padding-left: 20pt;text-indent: -14pt;text-align: justify;">3. предоставя информация на ТЪРГОВЕЦА относно планирани ремонти, включване на нови мощности и други дейности, които биха повлияли трайно на потреблението на електроенергия; </p>
            <p style="padding-left: 20pt;text-indent: -14pt;text-align: justify;">4. посочи лице за контакт в Приложение 2 към Договора, което да предоставя оперативна информация за планираните работни режими при поискване от страна на ТЪРГОВЕЦА.</p>
            <p style="padding-left: 20pt;text-indent: -14pt;text-align: justify;">5. изпълни всички необходими предпоставки за извършване на процедурата по смяна на доставчик съгласно Правилата за търговия на електрическа енергия към датата на  нейното извършване, като включително е отстранил всички причини за мотивирано възражение срещу процедурата ;</p>
      <p class="s9" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">(2) КЛИЕНТЪТ има право да:</p>
            <p style="padding-top: 1pt;padding-left: 25pt;text-indent: -19pt;text-align: left;">1. получава  пълната  информация  за  обектите  си,  предоставена  от  съответното разпределително или преносно дружество;</p>
            <p style="padding-left: 25pt;text-indent: -19pt;text-align: left;">2. получава своевременно информация при очаквани проблеми с продажбата и доставката на електроенергия;</p>
            <p style="padding-left: 25pt;text-indent: -19pt;line-height: 14pt;text-align: left;">3. да прекрати едностранно договора с едномесечно предизвестие, за което не дължи неустойка.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 6. (1) Доставката на електроенергия до точката на присъединяване на обектите на КЛИЕНТА се осъществява от съответното електроразпределително дружество, съгласно сключени договори за достъп и пренос между КЛИЕНТА и разпределителното дружество.  Рискът от некачествена доставка, дължаща се на преноса, се носи от преносното и/или разпределителното дружество – страна по договора за пренос.</p>
            <p class="s6" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">(2) ТЪРГОВЕЦЪТ не носи отговорност за причинени на КЛИЕНТА вреди, произтичащи от  евентуални смущения, прекъсвания и падове в напрежението, както и за всякакви отклонения от нормативните изисквания за качество на доставяната електрическа  енергия, настъпили вследствие от неизправност на съоръженията за пренос и/или разпределение на електрическа енергия, аварийни и планови прекъсвания, оперативни превключвания и други.</p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">(3) Никоя от СТРАНИТЕ няма да отговаря за претърпени от другата страна непреки щети или за щети, които не са произтекли от неизпълнение на задължение от страна по Договора или са резултат от невиновно поведение.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 6а. СТРАНИТЕ приемат, че лицата за контакт в Приложение 2, неразделна част от настоящия Договор са единствените оправомощени лица да водят комуникация по  отношение действието, изпълнението, изменението и/или прекратяването на Договора.</p>
      <p style="text-indent: 0pt;text-align: left;"><br></p>
      <p class="s5" style="text-align: center;">IV. ЦЕНИ, ФАКТУРИРАНЕ И НАЧИН НА ПЛАЩАНЕ</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 7. СТРАНИТЕ определят цена равна на почасовата цена на пазарен сегмент „ден-напред“ за MWh на „Българска независима енергийна борса“ ЕАД, съгласно измерените почасови количества за потребителския профил на КЛИЕНТА, към която се добавят ….,…. лв./MWh (………….. лева и ………… стотинки за мегаватчас). Цената не включва ДДС, акциз, цена „задължения към обществото", цени за мрежови услуги, както и други задължения, които могат да бъдат наложени в следствие на промяна на нормативната рамка.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 8. Фактурирането се извършва на база измерени количества. Данните за измерени количества се предоставят от съответното разпределително или преносно дружество и представляват основен документ, удостоверяващ продадените количества електрическа енергия. </p>
      <p style="padding-left: 5pt;text-indent: 0pt;line-height: 14pt;text-align: justify;">Чл. 9. (1) ТЪРГОВЕЦЪТ издава фактура за предоставените услуги до 10то число на месеца следващ доставката, с падеж за плащане – 5 дни.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">(2) В случай че КЛИЕНТЪТ изрично не възрази, ще получава издадените фактури на електронния адрес посочен в Приложение 2 и се считат за получени от КЛИЕНТА в деня на изпращането им от страна на ТЪРГОВЕЦА. В случай че КЛИЕНТЪТ откаже да му бъдат  изпращани на електронния адрес, то същите ще се считат за получени на датата, на която са предадени лично или чрез куриерска фирма.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 10. В случай че датата на падежа на задълженията е почивен ден, същите могат да бъдат заплатени до края на първия работен ден, следващ датата на падежа.</p>
      <p class="s2" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 11. Във фактурата по чл. 9 ТЪРГОВЕЦЪТ фактурира и дължимите от КЛИЕНТА суми за цена >за „задължения към обществото“, акциз, мрежови услуги и ДДС, съгласно условията на  Договора и действащите решения на Комисия за енергийно и водно регулиране /КЕВР/ или друг компетентен орган. </p>
      <p class="s11" style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 12. КЛИЕНТЪТ се съгласява, че ако в рамките на компетенциите си КЕВР приеме решение, с което се въвеждат промени в размера, структурата и/или начина на образуване на цените и ценовите компоненти, както и при други изменения в нормативната уредба, касаещи  ценообразуването, преноса, разпределението, доставката и търговията с електрическата енергия, в това число експлицитно въвеждането на 15 (петнадесет) минутен период на сетълмент и/или единна цена за балансираща енергия, ТЪРГОВЕЦЪТ има право, считано от  датата на влизане в сила на съответните промени, едностранно да отчете съответните промени  при калкулирането на потребената електрическа енергия. </p>
      <p style="padding-left: 5pt;text-indent: 0pt;line-height: 14pt;text-align: left;">Чл. 13. Плащанията се извършват в лева, по банков път, като извършващият банковия превод трябва да заплати собствените си банкови разноски.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;line-height: 14pt;text-align: left;">Чл. 14. (1) Клиентът има право на възражение срещу измерените и фактурирани стойности на количествата електрическа енергия.</p>
            <p class="s2" style="padding-left: 23pt;text-indent: -18pt;text-align: left;">(2) В случаите по ал. 1 ТЪРГОВЕЦЪТ изпраща възражението на КЛИЕНТА на съответния мрежови оператор.</p>
            <p style="padding-left: 22pt;text-indent: -16pt;text-align: left;">(3) Възражението по ал. 1 не освобождава от задължението за заплащане на издадената фактура.</p>
            <p style="padding-left: 22pt;text-indent: -16pt;text-align: left;">(4) След произнасянето на мрежовия оператор и издаване в съответствие с него дебитно или кредитно известие от ТЪРГОВЕЦА:</p>
            <p class="s2" style="padding-left: 16pt;text-indent: -10pt;text-align: left;">1. ТЪРГОВЕЦЪТ прихваща дължимите суми от последващите плащания; или</p>
            <p class="s2" style="padding-left: 17pt;text-indent: -12pt;text-align: left;">2. КЛИЕНТЪТ доплаща дължимите суми в срок от 5 (пет) дни от получаване на съответния документ от ТЪРГОВЕЦА.</p>
      <p style="text-indent: 0pt;text-align: left;"><br></p>
      <p class="s5" style="text-align: center;">V. ПРЕУСТАНОВЯВАНЕ И ВЪЗСТАНОВЯВАНЕ НА СНАБДЯВАНЕТО</p>
      <p class="s2" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 15.  (1) При просрочено задължение ТЪРГОВЕЦЪТ изпраща предизвестие на КЛИЕНТА, с което му дава допълнителен срок от 5 (пет) дни за заплащане на просрочените суми, след изтичането на който предприема съответните действия за временно преустановяване на снабдяването. </p>
            <p class="s2" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">(2) В случаите по ал. 1 по-горе ТЪРГОВЕЦЪТ има право да поиска от мрежовия оператор временно да преустанови снабдяването с електрическа енергия на обектите на КЛИЕНТА по  Приложение 1 от Договора.</p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">(3) В случай че ТЪРГОВЕЦЪТ понесе разходи във връзка с преустановяването и/или възстановяването на снабдяването, същите са за сметка на КЛИЕНТА и се възстановяват на ТЪРГОВЕЦА. </p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 16. В случаите на неизпълнение на други задължения по Договора от страна на КЛИЕНТА, ТЪРГОВЕЦЪТ има право да поиска от мрежовия оператор временно да преустанови  снабдяването с електрическа енергия на обектите на КЛИЕНТА по Приложение 1 от Договора.</p>
      <p class="s2" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 17. ТЪРГОВЕЦЪТ започва процедура за възстановяване на снабдяването с електрическа енергия на КЛИЕНТА след отпадане на основанието за преустановяването и след възстановяване от КЛИЕНТА на всички разходи, направени от ТЪРГОВЕЦА и/или съответния мрежови оператор за временно преустановяване и за възстановяване на снабдяването. </p>
      <p style="text-indent: 0pt;text-align: left;"><br></p>
      <p class="s1" style="text-align: center;">VI. ЖАЛБИ, СИГНАЛИ И ПРЕДЛОЖЕНИЯ</p>
      <p class="s2" style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 18. КЛИЕНТЪТ има право да подава жалби, сигнали и предложения във връзка с изпълнението на този договор до ТЪРГОВЕЦА по реда на „Правилата за работа с потребители на ЕНЕРГЕО ЕООД” http://energeo.bg/, публикувани на интернет страницата на ТЪРГОВЕЦА  - http://energeo.bg.</p>
      <p style="text-indent: 0pt;text-align: left;"><br></p>
      <p class="s5" style="text-align: center;">VII. ОБЕЗЩЕТЕНИЯ И НЕУСТОЙКИ</p>
      <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 19. (1) В случай че някоя от СТРАНИТЕ просрочи плащане, възникнало на основание, посочено в настоящия договор, то изправната страна ще има право да получи обезщетение в размер на законната лихва за забава от датата на падежа до датата на плащане.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">(2) В случай че ТЪРГОВЕЦЪТ не изпълни някое от поетите по силата на настоящия договор задължения, вкл. не спази изискванията за качество на договорените услуги и при неточно или забавено фактуриране, следва да покрие причинените от неизпълнението разходи за собствена сметка. </p>
      <p class="s2" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 20. СТРАНИТЕ не си дължат обезщетения в случай на въвеждане на ограничителен режим, временно прекъсване на пазара или ограничаване на количествата за доставка от страна на Оператора на енергийната система по реда и в случаите, указани в ПТЕЕ и Правилата за управление на електроенергийната система, и други относими нормативни актове. </p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 21. ТЪРГОВЕЦЪТ не носи отговорност за причинени вреди на КЛИЕНТА, които произтичат от преминаването на КЛИЕНТА към доставчик от последна инстанция в случаи на прекратяване на този договор или изключване на КЛИЕНТА от стандартната балансираща група на ТЪРГОВЕЦА. </p>
      <p style="text-indent: 0pt;text-align: left;"><br></p>
      <p class="s5" style="text-align: center;">VIII. ПОВЕРИТЕЛНОСТ НА ИНФОРМАЦИЯТА</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Чл. 22. Всяка от СТРАНИТЕ се задължава да не разкрива пред трети страни условията по Договора.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Чл. 23. (1) Изключение от разпоредбите на чл. 22 е възможно, когато:</p>
            <p style="padding-left: 17pt;text-indent: -11pt;text-align: justify;">1. информацията е разкрита с предварителното писмено съгласие на другата страна;</p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">2. информацията е разкрита с цел да бъде спазен действащ нормативен акт или във връзка със съдебно или административно производство. В тези случаи СТРАНИТЕ разкриват само изискуемата информация. Страната, разкриваща информацията, е задължена да уведоми  писмено другата страна за предоставянето на информацията;</p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">3. информацията е разкрита пред комисии по контрол на цените или с цел изчисляване на индекс и при условие че при това няма да бъде възможно идентифицирането на другата страна. </p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">(2) Всяка от СТРАНИТЕ по договора е длъжна да спазва изискването за поверителност на информацията до 5 (пет) години след изтичане срока на действие на Договора.</p>
      <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">Чл. 24. (1) За изпълнението на задълженията по този договор страните обработват необходимите лични данни на свои служители, които си предоставят една на друга.</p>
            <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">(2) Обработката на тези лични данни от страните се извършва в съответствие с разпоредбите на националното законодателство и на Регламент (ЕО) № 2016/679, и с цел изпълнение на задълженията им по Договора. </p>
            <p style="padding-left: 21pt;text-indent: -15pt;line-height: 14pt;text-align: justify;">(3) Страните се задължават:</p>
                  <p style="padding-left: 22pt;text-indent: -16pt;text-align: justify;">1. да обработват лични данни на служители на другата страна само при нейни документирани инструкции;</p>
                  <p style="padding-left: 18pt;text-indent: -13pt;text-align: justify;">2. да не използват данните за други цели, които не са свързани с и са несъвместими с обработката за целите на Договора;</p>
                  <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">3. да поддържат адекватни технически и организационни мерки, политики и процедури за гарантирана и законосъобразна обработка на лични данни и защита на данните от неоторизиран достъп, загуба, промяна или разкриване или други незаконни форми на обработка;</p>
                  <p style="padding-left: 18pt;text-indent: -12pt;text-align: justify;">4. да поддържат адекватни мерки за подпомагане на другата страна при изпълнение на задължението й да отговори на исканията на субектите на лични данни;</p>
                  <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">5. да предприемат необходимите действия, за да гарантират, че техните подизпълнители отговарят на изискванията на законодателството за защита на данните и същите са обвързани със задължения, идентични със задълженията на страната по Договор;</p>
                  <p style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">6. да гарантират, че лицата и подизпълнителите, оправомощени да обработват лични данни, са надлежно обучени и са длъжни да спазват поверителността по отношение на получените данни; </p>
                  <p style="padding-left: 17pt;text-indent: -11pt;line-height: 14pt;text-align: justify;">7. в случай че възникне нарушение на данните, незабавно да уведомят другата страна и да й сътрудничат за смекчаване на неблагоприятните последици;</p>
                  <p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">8. след изпълнение на задълженията си по този Договор да изтрият всички лични данни, получени във връзка с Договора, както и всички техни копия, които са им известни, освен ако  тяхното  съхранение  се  изискват  съгласно  законодателството  на  ЕС  или  законодателството на Република България;</p>
                  <p style="padding-left: 17pt;text-indent: -11pt;line-height: 14pt;text-align: justify;">9. да предоставят на другата страна достъп до информацията, необходима за доказване на изпълнението на задълженията, посочени в настоящия член.</p>
      <p style="text-indent: 0pt;text-align: left;"><br></p>
      <p class="s5" style="text-align: center;">IX. ЗАКЛЮЧИТЕЛНИ РАЗПОРЕДБИ</p>
<p class="s2" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 25. СТРАНИТЕ се споразумяват, че всяка промяна и/или допълнение към съдържанието на Договора, ще бъде в писмена форма.</p>
<p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 26. Никоя от СТРАНИТЕ няма право да  прехвърля на трета страна своите права и задължения, произтичащи от Договора без изричното писмено съгласие на другата страна. Чл. 27. СТРАНИТЕ заявяват, че са съгласни всички спорове по този Договор, включително и такива, свързани с неговото тълкуване, недействителност, изпълнение или прекратяване, както и приспособяването му към нововъзникнали обстоятелства, да се уреждат чрез преговори помежду им. </p>
<p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 28. В случай че споразумение не бъде постигнато, спорът ще бъде отнесен към компетентния държавен орган. Отнасянето на спора за разрешаване от компетентния държавен орган няма да бъде причина за прекратяване изпълнението на другите задължения по този Договор.</p>
<p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 29. За всички, неуредени в този Договор въпроси, се прилагат разпоредбите на действащото в Република България законодателство.</p>
<p class="s2" style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Чл. 30. При спор СТРАНИТЕ се задължават да представят всички документи, отнасящи се до спора. В случай на правни ограничения, промяна в законовата база или противоречия с правните норми, засегната страна по изпълнението на настоящия Договор ще информира другата страна писмено и ще изготви съответния анекс за изменение или допълнение към Договора, за да бъде последният в съответствие с променените условия. </p>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Неразделна част от Договора са:</p>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<p class="s2" style="padding-left: 5pt;text-indent: 0pt;line-height: 106%;text-align: left;">Приложение 1: Списък с обекти на КЛИЕНТА Приложение 2: Списък с лица за контакт на СТРАНИТЕ Приложение 3: Пълномощно </p>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<p style="padding-left: 5pt;text-indent: 0pt;text-align: justify;">Настоящият договор се изготви и подписа в два еднообразни екземпляра, по един за всяка от СТРАНИТЕ.</p>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<table style="border-collapse:collapse;" cellspacing="0">
   <tr style="height:14pt">
      <td style="width:170pt">
         <p class="s17" style="padding-left: 2pt;text-indent: 0pt;line-height: 11pt;text-align: left;">ТЪРГОВЕЦ:</p>
      </td>
      <td style="width:178pt">
         <p class="s17" style="padding-left: 67pt;text-indent: 0pt;line-height: 11pt;text-align: left;">КЛИЕНТ:</p>
      </td>
   </tr>   
   <tr style="height:14pt">
      <td style="width:170pt">
         <p class="s17" style="padding-left: 2pt;text-indent: 0pt;line-height: 11pt;text-align: left;">Константин Делисивков</p>
      </td>
      <td style="width:178pt">
         <p class="s17" style="padding-left: 67pt;text-indent: 0pt;line-height: 11pt;text-align: left;">{NAME}</p>
      </td>
   </tr>
   <tr style="height:17pt">
      <td style="width:170pt">
         <p class="s14" style="padding-left: 2pt;text-indent: 0pt;text-align: left;">Управител</p>
      </td>
      <td style="width:178pt">
         <p class="s21" style="padding-left: 67pt;text-indent: 0pt;text-align: left;">.....................</p>
      </td>
   </tr>
   <tr style="height:14pt">
      <td style="width:170pt">
         <p class="s17" style="padding-left: 2pt;text-indent: 0pt;line-height: 12pt;text-align: left;">"ЕНЕРГЕО“ ЕООД </p>
      </td>
      <td style="width:178pt">
         <p class="s17" style="padding-left: 67pt;text-indent: 0pt;line-height: 12pt;text-align: left;">{COMPANY}</p>
      </td>
   </tr>
</table>
`;

const EnergeoOfferPreview = ({ data, watermark, logo, ...props }) => {
  return (
      <StyledWrapper
        id='htmlPdfContract'
        {...props}
        style={{
         fontFamily: 'Roboto-Regular',
         letterSpacing: '1px'
        }}
        // dangerouslySetInnerHTML={{ __html: getFormattedData(offerHtml, data, logo, getMeasurePointsHtml)}}
      >
        <StyledPageWrapper className="new-pdf-page" dangerouslySetInnerHTML={{ __html: getFormattedData(offerHtml, data, logo)}} />
      </StyledWrapper>
    )
};

export default EnergeoOfferPreview;