import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled('div')`
  .btn--secondary {
    border: none !important;
  }
`;

function BorderlessButton(props) {
  return (
    <StyledWrapper>
      <button
        type="button"
        className="btn btn--secondary d-flex align-items-center"
        {...props}
      ></button>
    </StyledWrapper>
  );
}

export default BorderlessButton;
