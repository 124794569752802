import { Tooltip } from '@mui/material'
import { tooltipClasses } from '@mui/material/Tooltip'
import PropTypes from 'prop-types'
import React from 'react';
import styled from 'styled-components';

const StyledTooltipContent = styled.div`
  p {
    color: black;
  }
`

function SecondaryTooltip({children, content, ...tooltipProps}) {
    return (
    <Tooltip
      componentsProps={{
        tooltip: {
          style: {
            backgroundColor: 'white',
            border: '1px solid #e6401e',
            borderTop: '3px solid #e6401e',
            boxShadow: '0px 3px 3.5px rgba(0, 0, 0, 0.4)',
            bordeRadius: '5px',
          },
        },
        arrow: {
          style: {
            color: '#e6401e',
          }
        }
      }}
      arrow
      title={
        <StyledTooltipContent>
          <p>{content}</p>
        </StyledTooltipContent>
      }
      {...tooltipProps}
    >
      {children}
    </Tooltip>
  )
}

SecondaryTooltip.propTypes = {
  tooltipProps: PropTypes.object,
  content: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default SecondaryTooltip