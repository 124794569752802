import { styled } from "@mui/material"
import React from "react"
import SvgLogoIcon from "../../../images/zoom-out.inline.svg"

const StyledIcon = styled(SvgLogoIcon)``;

const ZoomOut = ({ width = "24", height = "24", ...props }) => {
  return (
    <>
      <StyledIcon width={width} height={height} {...props} />
    </>
  )
}

export default ZoomOut
