import { StepConnector, styled, stepConnectorClasses } from '@mui/material';
import React from 'react';

const CustomConnector = styled(StepConnector)(({ theme }) => `
  &.${stepConnectorClasses.active} {
    .${stepConnectorClasses.line} {
      border-color: ${theme.palette.secondary.main};
    }
  
    .${stepConnectorClasses.line} {
      border-color: ${theme.palette.secondary.main};
    }
  }

  &.${stepConnectorClasses.completed} {
    .${stepConnectorClasses.line} {
      border-color: ${theme.palette.secondary.main};
    }
  
    .${stepConnectorClasses.line} {
      border-color: ${theme.palette.secondary.main};
    }
  }

  & .${stepConnectorClasses.line} {
    border-top-width: 3px;
    border-radius: 4px;
  }

  & .${stepConnectorClasses.lineVertical} {
    border-left-width: 3px;
    border-radius: 2px;
    display: inline;
    margin-left: 2px;
    padding: 8px 0px;
  }
`);

export default CustomConnector;