import { Box, styled, TextField, Typography, inputBaseClasses, textFieldClasses } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ZoomIn from '../../../atoms/icons/ZoomIn';
import ZoomOut from '../../../atoms/icons/ZoomOut';

const StyledTextField = styled(TextField)(({}) => `
  & .${inputBaseClasses.root} {
    max-height: 45px;
  }
`);

const StyledProcentField = styled(StyledTextField)`
  &.${textFieldClasses.root} {
    margin: 0 15px !important;
  }
`;

const StyledWrapper = styled(Box)`
  & {
    position: sticky;
    top: 0;
    right: 50%;
    background-color: inherit;
    padding: 2rem 0rem;
    border-radius: 10px;
    z-index: 100;
    width: 100%;
  }

  &.offer-document-zoom-navigation {
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 100;
    padding: 2rem;
    border-radius: 10px;
  }
`;

const PreviewNavigation = ({ zoomIn, zoomOut, zoomLevel: zoomLevelProp, setZoom, zoomToElement, limit }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageInput, setPageInput] = useState('1');
  const [zoomInput, setZoomInput] = useState(`${(zoomLevelProp * 100).toFixed(0)}%`);

  useEffect(() => {
    setZoomInput(`${(zoomLevelProp * 100).toFixed(0)}%`);
  }, [zoomLevelProp]);

  const handlePageInputChange = (event) => {
    const { value } = event.target;
    
    if (value === '') {
      setPageInput(value);
    }

    const number = Number(value);

    if (number && number <= limit && number >= 1) {
      setPageInput(value);
    }
  };

  const handlePageChange = (e) => {
    const value = Number(pageInput);

    if (!value || value > limit || value < 1) {
      setPageInput(currentPage);
      return;
    }

    const pageElements = document.getElementsByClassName('offerDocumentPage');

    if (!pageElements || pageElements.length < 1) {
      return;
    }

    pageElements[value - 1].scrollIntoView();

    setCurrentPage(value);
  }

  const handleImperativeBlur = (e, callback) => {
    if (e.code === 'Enter' || e.key === 'Enter') {
      callback();
      e.target.blur();
      e.target.parentElement.focus();
    }
  }

  const handleZoomInputChange = (e) => {
    const { value } = e.target;

    setZoomInput(`${value.replace('%', '')}%`);
  }

  const handleZoomInputBlur = (e) => {
    const value = Number(zoomInput.replace('%', ''));

    if (!value || value < 100 || value >= 700) {
      setZoomInput(`${zoomLevelProp * 100}%`);
      return;
    }

    const zoomValue = value / 100;

    setZoom(zoomValue);
  }
  
  return (
    <StyledWrapper
      id="preview-offer-navigation-controls"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        height: '2rem',
        mb: 2,
      }}
    >
      <Box sx={{
        display: 'flex',
        width: { md: '50%'},
        justifyContent: 'end',
        alignItems: 'center',
        borderRight: '1px solid black',
        pr: '20px'
      }}>
        <StyledTextField
          sx={{
            maxWidth: "40px"
          }}
          value={pageInput}
          onBlur={handlePageChange}
          onChange={handlePageInputChange}
          onKeyDown={(e) => handleImperativeBlur(e, handlePageChange)}
        />
        <Typography variant="body2" >{`/ ${limit}`}</Typography>
      </Box>
  
      <Box sx={{
        display: 'flex',
        width: { md: '50%'},
        alignItems: 'center',
        justifyContent: 'start',
        pl: '5px'
      }}>
        <ZoomOut
          sx={(theme) => ({
            color: theme.palette.secondary.main,
          })}
          onClick={() => zoomOut()}
        />
  
        <StyledProcentField
          sx={{
            maxWidth: "80px",
          }}
          onChange={handleZoomInputChange}
          onBlur={handleZoomInputBlur}
          onKeyDown={(e) => handleImperativeBlur(e, handleZoomInputBlur)}
          value={zoomInput}
        />
  
        <ZoomIn
          sx={(theme) => ({
            color: theme.palette.secondary.main,
          })}
          onClick={() => zoomIn(0.1)}
        />
      </Box>
    </StyledWrapper>
  )
};

PreviewNavigation.propTypes = {
  zoomIn: PropTypes.func.isRequired,
  zoomOut: PropTypes.func.isRequired,
  zoomToElement: PropTypes.func.isRequired,
  zoomLevel: PropTypes.number.isRequired,
  setZoom: PropTypes.func.isRequired,
}

export default PreviewNavigation;