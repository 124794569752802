import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogContent, styled, Typography, dialogClasses } from '@mui/material';

import ContractCompletedIcon from '../../atoms/icons/ContractCompleted';

const StyledDialog = styled(Dialog)(() => `
  & .${dialogClasses.paper} {
    max-width: 60rem;
  }
`);

const StyledDialogContent = styled(DialogContent)(({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 2rem;
`);

const StyledButton = styled(Button)(({ theme }) => `
  &.MuiButton-root {
    padding: 10px 7px!important;
    font-weight: 500!important;
    font-size: 18px!important;
    line-height: 26px!important;
    min-height: auto!important;
    max-width: 270px;
    margin-bottom: 10px;
    background-color: ${theme.palette.secondary.main} !important;
  }

  &.MuiButton-root.Mui-disabled {
    opacity: 0.5;
  }
`);

const ContractCompleteModal = ({ open, redirect, ...props }) => {
  return (
    <StyledDialog open={open} {...props}>
      <StyledDialogContent dividers sx={{
        padding: {
          xs: '0.5rem 0.5rem',
          sm: '0rem 7rem',
          md: '1rem 7rem',
          lg: '2.2rem 14rem'
        },
        gap: {
          xs: '1rem',
          md: '2rem'
        },
      }}>
        <ContractCompletedIcon style={{ minH: '50px' }} />

        <Typography variant="h4" textAlign="center">Вашият договор се изготвя!</Typography>

        <Typography variant="body1" textAlign="center">
          Въведените от вас данни са в процес на обработка. Договорът 
          по избраната оферта ще получите на посочения от вас адрес 
          заедно с указания за подписването му.
        </Typography>

        <StyledButton
          sx={(theme) => ({
            backgroundColor: theme.palette.secondary.main,
          })}
          onClick={redirect}
        >
          ОБРАТНО КЪМ НАЧАЛО
        </StyledButton>
      </StyledDialogContent>
    </StyledDialog>
  )
};

ContractCompleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  redirect: PropTypes.func.isRequired,
}

export default ContractCompleteModal;