import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import styled from 'styled-components';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import PreviewNavigation from './PreviewNavigation';
import EnergoOfferPreview from './offer-html/EnergoOfferPreview';

import '../../../../styles/transform.css';
import SinergonOfferPreview from './offer-html/SinergonOfferPreview';
import TokiGreenPreview from './offer-html/TokiGreenPreview';
import TokiPreview from './offer-html/TokiPreview';
import RitmPreview from './offer-html/RitmPreview';
import EnergyOperationsProcentPreview from './offer-html/EnergyOperationsProcentPreview';
import EnergyOperationsFixedPreview from './offer-html/EnergyOperationsFixedPreview';
import EnergyOperationsPreview from './offer-html/EnergyOperationsPreview';
import RitmProcentPreview from './offer-html/RitmProcentPreview';
import EnergeoOfferPreview from './offer-html/EnergeoOfferPreview';

const StyledWrapper = styled(Box)`
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  padding: 2.4rem 1rem;
  padding-bottom: 2px;
  border-bottom: 1px solid #E0E0E0;
  position: relative;

  .contract-image-cut .contract-image-full {
    display: flex;
  }

  .gradient-fade {
    position: absolute;
    bottom: 0;
    width: 80%;
    height: 128px;
    background: linear-gradient( to bottom,rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100% );
  }

  .contract-hidden {
    height: 279px;
    background-size: cover;
    background-position: top;
    object-fit: cover;
    object-position: top;
  }

  .contract-image-full {
    height: 100%;
  }
`;

const StyledTransformWrapper = styled(TransformWrapper)`
 & {
   width: 100%;
 }
`;

const synergonFileName = 'Комбиниран договор _Синергон Енерджи ООД.pdf';
const tokiGreenFileName = '1_ДКУОУЕФСГ-30.pdf';
const tokiFileName = 'Toki2.pdf';
const ritmFixedFileName = '№ 108 el_ КОМБИНИРАН ДОГОВОР РИТЪМ - ЮЛ - Борса +, с Неустойки.pdf';
const ritmProcentFileName = '№ 109 el_ КОМБИНИРАН ДОГОВОР РИТЪМ - ЮЛ - Борса +-, с Неустойки.pdf';
const energyOperationsProcentFileName = 'Dogovor_ЕО BEX+_.pdf';
const energyOperationsFixedBEXFileName = 'Dogovor_ЕО BEX+фикс.pdf';
const energyOperationsFileName = 'Dogovor_ЕО фикс.pdf';
const energeoFileName = 'Contract_Energeo_Borsa.pdf';

// Use this to show html contract
function OfferPreview({ showFullOffer, data, watermark, fileName, wrapperStyle, logo }) {
  const [zoomLevel, setZoomLevel] = useState(1);
  const transformWrapperRef = useRef(null);

  const zoomIn = (setTransform) => {
    if (zoomLevel >= 7) {
      return;
    }

    setTransform(
      transformWrapperRef.current.instance.transformState.positionX,
      transformWrapperRef.current.instance.transformState.positionY,
      transformWrapperRef.current.instance.transformState.scale + 0.1
    );
    setZoomLevel(prev => prev + 0.1);
  }

  const zoomOut = (setTransform) => {
    if (zoomLevel <= 1) {
      setZoomLevel(1);
      return;
    }

    setTransform(
      transformWrapperRef.current.instance.transformState.positionX,
      transformWrapperRef.current.instance.transformState.positionY,
      transformWrapperRef.current.instance.transformState.scale - 0.1
    );
    setZoomLevel(prev => prev - 0.1);
  }

  const handleZoomLevelChange = (value) => {
    if (!value || value < 1 || value >= 7) {
      return;
    }

    setZoomLevel(value);

    if (value === 1) {
      transformWrapperRef.current.resetTransform();
      return;
    }

    transformWrapperRef.current.setTransform(
      transformWrapperRef.current.instance.transformState.positionX,
      transformWrapperRef.current.instance.transformState.positionY,
      value
    );
  }

  const renderOffer = useCallback(() => {
     const offerProps = {
      data: data || {},
      watermark,
      logo,
     };

     if (!showFullOffer) {
      offerProps.className = 'contract-hidden';
     }

     if (!fileName) {
      return <EnergoOfferPreview {...offerProps} />
     }


     if (fileName === synergonFileName) {
      return <SinergonOfferPreview {...offerProps} />
     } else if (fileName === tokiGreenFileName) {
      return <TokiGreenPreview {...offerProps} />
     } else if (fileName === tokiFileName) {
      return <TokiPreview {...offerProps} />
     } else if (fileName === ritmFixedFileName) {
      return <RitmPreview {...offerProps} />
     } else if (fileName === ritmProcentFileName) {
      return <RitmProcentPreview {...offerProps} />
     } else if (fileName === energyOperationsProcentFileName) {
      return <EnergyOperationsProcentPreview {...offerProps} />
     } else if (fileName === energyOperationsFixedBEXFileName) {
      return <EnergyOperationsFixedPreview {...offerProps} />
     } else if (fileName === energyOperationsFileName) {
      return <EnergyOperationsPreview {...offerProps} />
     } else if (fileName === energeoFileName) {
      return <EnergeoOfferPreview {...offerProps} />
     }
  }, [showFullOffer, data, watermark, fileName]);

  if (!showFullOffer) {
    return (
      <StyledWrapper sx={{ overflow: 'hidden', p: { xs: '0', md: '2.4rem 1rem 2px' } }} style={wrapperStyle}>
        {renderOffer()}
      </StyledWrapper>
    )
  }
  
  return (
    <>
      <StyledTransformWrapper
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
        wheel={{
          disabled: true,
          touchPadDisabled: true,
        }}
        ref={transformWrapperRef}
        panning={{
          disabled: true,
        }}
        doubleClick={{
          disabled: true,
        }}
        onZoom={(instance, event) => {
          handleZoomLevelChange(instance.state.scale);
        }}
        limitToBounds
      >
        {({ zoomToElement, setTransform }) => {
          return (
            <StyledWrapper
              sx={{
                p: { xs: '0', md: '2.4rem 1rem 2px' },
                overflow: 'auto'
              }}
              style={wrapperStyle}
              >
              <PreviewNavigation
                zoomIn={() => zoomIn(setTransform)}
                zoomOut={() => zoomOut(setTransform)}
                zoomToElement={zoomToElement}
                zoomLevel={zoomLevel}
                setZoom={handleZoomLevelChange}
                limit={8}
              />
              <TransformComponent wrapperStyle={{ overflow: 'auto' }}>
                {renderOffer()}
              </TransformComponent>
            </StyledWrapper>
          )
        }}
      </StyledTransformWrapper>
    </>
  )
}

OfferPreview.propTypes = {
  showFullOffer: PropTypes.bool,
  data: PropTypes.object,
}

export default OfferPreview