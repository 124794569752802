import { CircularProgress, TextField, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { Button } from '@material-ui/core';
import ErrorIcon from '@mui/icons-material/Error';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { getCompany, validateEIK } from '../../../../helpers/getEIK';
import { privateDataValidationSchema } from '../validation/schemas';
import SecondaryTooltip from '../../../atoms/tooltips/SecondaryTooltip';
import FormSubmitButton from '../FormSubmitButton';
import EikField from '../../../organisms/eik-field/EikField';
import { fireGAEvent } from '../../../../helpers/ga';

const StyledEIKTooltip = styled(SecondaryTooltip)`
  position: absolute;
  top: 25px;
`;

function PrivateDataFormFields({ initialValues, submitBtnProps, onSave }) {
  const [retrievingCompanyData, setRetrievingCompanyData] = useState(false);

  const theme = useTheme();
  const isBelowMdBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  const handleSubmitForm = (values, actions) => {
    fireGAEvent("sign_contract_first_step", { ...values });

    onSave(values);
  };

  const handleSetRetrievingCompanyData = useCallback((isRetrieving) => {
    setRetrievingCompanyData((prev) => prev !== isRetrieving
      ? isRetrieving
      : prev
    );
  }, [setRetrievingCompanyData]);

  const fillFieldsWithCompanyData = useCallback(async (companyData, setValues) => {
    setValues((fields) => {
      const {
        name: companyName,
        address,
        managers,
        contacts,
      } = companyData;

      if (!companyData || companyData.error) {
        return fields;
      }
  
      const fieldsChange = {
        company: companyName,
      };
  
      if (managers && managers.length > 0) {
        const manager = managers[0];

        fieldsChange.names = manager;
      }
  
      if (address && !isEmpty(address)) {
        const { settlement, postCode, street, streetNumber } = address;

        if (settlement) {
          fieldsChange.city = settlement;
        }

        if (postCode) {
          fieldsChange.zip = postCode;
        }

        const concatStreetAndNum = [street, streetNumber].filter(Boolean).join(' ');

        if (!isEmpty(concatStreetAndNum)) {
          fieldsChange.street = concatStreetAndNum;
        }
      }
      if (contacts && !isEmpty(contacts)) {
        const { phone, email } = contacts;

        if (email) {
          fieldsChange.email = email;
        }

        if (phone) {
          fieldsChange.phone = phone.split(' ').join('');
        }
      }

      const change = {
        ...fields,
        ...fieldsChange,
      };

      return change;
    }, true);
  }, [isEmpty]);

  const renderForm = useCallback(() => (
    <Formik
      initialValues={initialValues}
      validationSchema={privateDataValidationSchema}
      onSubmit={handleSubmitForm}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        touched,
        setFieldValue,
        setValues,
      }) => {
        return (
          <>
            <div className="row">
              <div className="col-md-6" style={{ position: 'realtive' }}>
                <EikField
                  id="eik"
                  name="eik"
                  label="ЕИК"
                  variant="outlined"
                  style={{ margin: 8 }}
                  error={touched.eik && Boolean(errors.eik)}
                  helperText={touched.eik && errors.eik}
                  value={values.eik}
                  onChange={(e) => {
                    if (!Number(e.target.value) && e.target.value != 0) {
                      return;
                    }

                    handleChange(e);
                  }}
                  onRetrieveEikData={(companyData) => fillFieldsWithCompanyData(companyData, setValues)}
                  loading={retrievingCompanyData}
                  setLoading={handleSetRetrievingCompanyData}
                />
                {/* <StyledEIKTooltip
                  content="ЕИК е 10-цифрен, уникален идентификационен номер, който улеснява вашия достъп до информация и услуги. Намира се във фактурата за електроенергия."
                  style={{
                    position: 'absolute',
                  }}
                >
                  <ErrorIcon
                    style={{
                      color: '#f89900',
                    }}
                  />
                </StyledEIKTooltip> */}
              </div>

              {retrievingCompanyData && (
                <div className="col-md-1" style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  <CircularProgress style={{
                    color: theme.palette.secondary.main,
                  }} />
                </div>
              )}
            </div>

            <div className="row">
              <div className="col-md-6">
                <TextField
                  id="company"
                  name="company"
                  label="Фирма"
                  variant="outlined"
                  style={{ margin: 8 }}
                  onChange={handleChange}
                  value={values.company}
                  error={touched.company && Boolean(errors.company)}
                  helperText={touched.company && errors.company}
                />
              </div>

              <div className="col-md-6">
                <TextField
                  id="names"
                  name="names"
                  label="Име и фамилия на представляващия"
                  variant="outlined"
                  fullWidth
                  style={{ margin: 8 }}
                  value={values.names}
                  onChange={handleChange}
                  error={touched.names && Boolean(errors.names)}
                  helperText={touched.names && errors.names}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <TextField
                  id="phone"
                  label="Tелефон"
                  variant="outlined"
                  style={{ margin: 8 }}
                  value={values.phone}
                  onChange={(e) => {
                    if (!Number(e.target.value) && e.target.value != 0) {
                      return;
                    }
                    handleChange(e);
                  }}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone || errors.phone ? errors.phone : "Не е задължително"}
                />
              </div>

              <div className="col-md-6">
                <TextField
                  id="email"
                  name="email"
                  label="Имейл"
                  value={values.email}
                  onChange={handleChange}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  variant="outlined"
                  style={{ margin: 8 }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <TextField
                  name="otherEmail"
                  label="Имейл за електронна фактура"
                  variant="outlined"
                  fullWidth
                  style={{ margin: 8 }}
                  onChange={handleChange}
                  value={values.otherEmail}
                  error={touched.otherEmail && Boolean(errors.otherEmail)}
                  helperText={touched.otherEmail && errors.otherEmail ? errors.otherEmail : "Не е задължително"}
                />
              </div>
            </div>

            <hr />

            <p>Адрес по регистрация</p>

            <div className="row">
              <div className="col-md-6">
                <TextField
                  id="city"
                  name="city"
                  label="Населено място"
                  variant="outlined"
                  style={{ margin: 8 }}
                  value={values.city}
                  onChange={handleChange}
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                />
              </div>

              <div className="col-md-6">
                <TextField
                  id="zip"
                  name="zip"
                  label="Пощенски код"
                  variant="outlined"
                  style={{ margin: 8 }}
                  value={values.zip}
                  onChange={(e) => {
                    if (!Number(e.target.value) && e.target.value != 0) {
                      return;
                    }
                    
                    handleChange(e);
                  }}
                  error={touched.zip && Boolean(errors.zip)}
                  helperText={touched.zip && errors.zip}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <TextField
                  name="street"
                  label="Улица с номер, вход"
                  variant="outlined"
                  style={{ margin: 8 }}
                  fullWidth
                  value={values.street}
                  onChange={handleChange}
                  error={touched.street && Boolean(errors.street)}
                  helperText={touched.street && errors.street}
                />
              </div>
            </div>

            {isBelowMdBreakpoint && <hr />}

            <div className="row justify-content-end">
              <div className="col-md-3">
                <FormSubmitButton
                  style={{
                    backgroundColor: theme.palette.secondary.main,
                  }}
                  {...submitBtnProps}
                  onClick={handleSubmit}
                  disabled={[
                    values.eik,
                    values.company,
                    values.names,
                    values.email,
                    values.city,
                    values.zip,
                    values.street,
                  ].some((v) => !v)}
                >
                  {submitBtnProps.buttonText}
                </FormSubmitButton>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  ), [initialValues, privateDataValidationSchema, handleSubmitForm, isBelowMdBreakpoint]);

  return renderForm();
}

PrivateDataFormFields.propTypes = {
  initialValues: PropTypes.object.isRequired,
  submitBtnProps: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default PrivateDataFormFields;
