import { Delete } from '@mui/icons-material';
import {
  Button,
  TextField,
  styled,
  ClickAwayListener,
  useTheme,
  useMediaQuery,
  Box,
} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import Image from '../../../atoms/image/Image';
import IconAttachedRed from '../../../../images/attached-file--red.png';
import IconAttachedWhite from '../../../../images/attached-file--white.png';
import AddressCheckbox from './AddressCheckbox';
import OperatorsBase from '../../../atoms/select/OperatorsBase';
import ITNTooltip from '../../../atoms/tooltips/ITNTooltip';

const StyledUploadBtn = styled(Button)`
  span {
    text-transform: none !important;
  }
`;

const StyledITNTooltip = styled(ITNTooltip)`
  position: absolute;
  top: 27px;
`;

const StyledUploadImgWrapper = styled('label')`
  .MuiButton-root.btn--secondary {
    margin: 12px 0 !important;
  }
`;

const StyledRemoveWrapper = styled('div')`
  .list-group-item__delete {
    color: #e6401e;
    font-size: 15px;
  }
`;

function MeasurePointBox({
  removePoint,
  addPoint,
  pointData,
  index,
  setFieldValue,
  pointTouched,
  pointErrors,
  fileName,
  formikHelpers: { handleChange, values },
  resetPointAddress,
  ...props
}) {  
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const theme = useTheme();

  const isMdBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  const handleTooltipClose = () => setTooltipOpen(false);

  const handleTooltipOpen = () => setTooltipOpen(true);

  const renderTooltip = useCallback(() => {    
    let iconProps = {
      sx: (muiTheme) => ({
        color: muiTheme.palette.secondary.main,
        marginLeft: {
          xs: '0rem',
          lg: '0.1rem',
        },
        right: {
          xs: '25px',
          lg: '-11px',
        }
      })
    };

    let tooltipProps = {
      enterDelay: 0,
      enterTouchDelay: 0,
      leaveTouchDelay: 2500,
    };

    if (isMdBreakpoint) {
      tooltipProps = {
        PopperProps: {
          disablePortal: true,
        },
        open: tooltipOpen,
        onClose: handleTooltipClose,
        disableFocusListener: true,
        disableHoverListener: true,
        disableTouchListener: true,
      };

      iconProps = {
        ...iconProps,
        onClick: handleTooltipOpen,
      }

      return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Box>
            <StyledITNTooltip
              {...tooltipProps}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <ErrorIcon
                {...iconProps}
              />
            </StyledITNTooltip>
          </Box>
        </ClickAwayListener>
      )
    }

    return (
      <StyledITNTooltip
        {...tooltipProps}
      >
        <ErrorIcon
          {...iconProps}
        />
      </StyledITNTooltip>
    )
  }, [isMdBreakpoint, tooltipOpen]);

  return (
    <div className="list-group border-gray py-2 px-2 my-2">
      <div className="list-group-item">
        <div className="d-flex align-items-center justify-content-between mb-020">
          <h5 style={{ margin: 0 }}>Точка {index + 1}</h5>

          {values.points.length > 1 && (
            <StyledRemoveWrapper className="" onClick={(e) => removePoint(e)}>
              <div className="list-group-item__delete d-flex align-items-center">
                <Delete style={{ height: '18px' }} />
                <span> Премахни</span>
              </div>
            </StyledRemoveWrapper>
          )}
        </div>

        <div className="row">
          <div className="col-md-6">
            <TextField
              name={`points.${index}.itn`}
              label="ИТН / Абонатен номер"
              variant="outlined"
              style={{ margin: 8 }}
              onChange={handleChange}
              value={pointData.itn}
            />

            {renderTooltip()}
          </div>

          <div className="col-md-6">
            <div>
              <StyledUploadImgWrapper htmlFor={`points.${index}.fileName`}>
                <input
                  type="file"
                  name={`points.${index}.file`}
                  onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      const file = e.target.files[0];

                      // alert if size is bigger than 20MB
                      if (file.size > 20000000) {
                        alert('Размерът на файла не трябва да надвишава 20МБ');
                        return;
                      }

                      setFieldValue(`points.${index}.file`, file);
                      setFieldValue(`points.${index}.fileName`, file.name);
                    }
                  }}
                  id={`points.${index}.fileName`}
                  style={{ display: 'none' }}
                />

                <StyledUploadBtn
                  variant="contained"
                  component="span"
                  className={`btn btn--secondary btn--wide d-flex align-items-center justify-content-center ${
                    values.points[index].fileName?.length && 'btn--selected'
                  }`}
                >
                  <Image
                    className="btn__icon"
                    src={
                      values.points[index].fileName?.length > 0
                        ? IconAttachedWhite
                        : IconAttachedRed
                    }
                  ></Image>
                  <span>
                    {values.points[index].fileName?.length > 0
                      ? values.points[index].fileName
                      : 'Прикачи фактура'}
                  </span>
                </StyledUploadBtn>
              </StyledUploadImgWrapper>
            </div>
            <p className="small-text">Фактурата може да съдържа вашия ИТН</p>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-md-6">
            <OperatorsBase
              label="Оператор"
              value={values.points[index].operator}
              name={`points.${index}.operator`}
              onChange={handleChange}
              error={pointTouched.operator && Boolean(pointErrors.operator)}
              helperText={pointTouched.operator && pointErrors.operator}
            />
          </div>
        </div>

        <h5>Адрес на обекта</h5>

        <AddressCheckbox
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          index={index}
          pointData={pointData}
          pointTouched={pointTouched}
          pointErrors={pointErrors}
          resetPointAddress={resetPointAddress}
        />
      </div>
    </div>
  );
}

MeasurePointBox.propTypes = {
  removePoint: PropTypes.func.isRequired,
  addPoint: PropTypes.func,
  index: PropTypes.number.isRequired,
  pointData: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  pointTouched: PropTypes.object.isRequired,
  pointErrors: PropTypes.object.isRequired,
  formikHelpers: PropTypes.object.isRequired,
  fileName: PropTypes.func.isRequired,
  resetPointAddress: PropTypes.func.isRequired,
};

export default MeasurePointBox;
