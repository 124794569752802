import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import PrivateDataFormFields from './PrivateDataFormFields';

function PrivateDataForm({
  initialValues: {
    eik,
    company,
    names,
    email,
    phone,
    city,
    zip,
    street,
    otherEmail,
  },
  next,
  back,
  onSave,
  currentStep
}) {
    const handleSave = (values) => {
      onSave(values, currentStep);

      if (next) {
        next();
      }
    }

    return (
      <div className="row">
        <div className="col-md-12" style={{ padding: '2.4rem' }}>
          <Typography variant="h4" mb={3}>Данни за фирмата</Typography>

          <p className="small-text">Попълнете детайлите</p>

          <PrivateDataFormFields
            initialValues={{
              eik,
              company,
              names,
              email,
              otherEmail,
              phone,
              city,
              zip,
              street,
            }}
            submitBtnProps={{
              buttonText: 'Продължи',
              endIcon: <NavigateNextIcon />,
            }}
            onSave={handleSave}
          />
        </div>
      </div>
    )
}

PrivateDataForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  next: PropTypes.func,
  back: PropTypes.func,
  currentStep: PropTypes.number.isRequired,
}

export default PrivateDataForm