import React, { useCallback } from 'react';
import { styled } from '@mui/material';
import dayjs from 'dayjs';
import { getFormattedData } from './utils/helpers';
import { OPERATOR_OPTIONS_MAP } from '../../../../../helpers/constants';

const StyledWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
`;

const StyledPageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  font-family: 'Roboto-Regular';
  letter-spacing: 1px;
  
  span {
   all: inherit !important;
  }
  
  p, ol {
   font-size: 22px !important;
   line-height: 22px !important;
   color: black;
  }
  
  b {
   font-size: 24px;
  }
  
  ol {
   padding-left: 15px;
  }
  
  ol > li {
   list-style: none;
  }
`;

const StyledDataElement = styled('strong')`
  border-bottom: 1px dotted black;
  white-space: pre;
`;

const StyledDocumentPage = styled('div')`
  padding: 2rem;
  background-color: white;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
  position: relative;
`;

const StyledDocumentTitle = styled('b')`
  display: block;
  width: 100%;
  text-align: center;
`;

const StyledColumn = styled('td')`
  width: 50%;
  border: none;
`;

const StyledDiagonalText = styled('span')`
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  transform-origin: top left;
  background-color: transparent;
  color: rgb(0 0 0 / 9%);
  padding: 10px;
  font-size: 180px; 
`;

const StyledPageNumber = styled('h6')`
  text-align: end;
`;

const ritmHtml = `
{LOGO_HEADER}
<p style="text-indent: 0pt;text-align: left;"><br></p>
<p style="text-indent: 0pt;line-height: 1pt;text-align: left;"></p>
<b style="padding-top: 2pt;padding-left: 6pt;text-indent: 0pt;text-align: center;">КОМБИНИРАН ДОГОВОР ЗА ПРОДАЖБА И БАЛАНСИРАНЕ НА</b>
<b style="padding-top: 2pt;padding-left: 6pt;text-indent: 0pt;text-align: center;">ЕЛЕКТРИЧЕСКА ЕНЕРГИЯ НА КРАЕН КЛИЕНТ № ELCA......./{DATE} г.</b>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<p style="padding-top: 12pt;padding-left: 6pt;text-indent: 0pt;text-align: justify;">Днес {DATE} г., между:</p>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<p style="text-indent: 0pt;text-align: left;"><span></span></p>
<p></p>
<p class="s1" style="padding-left: 6pt;text-indent: 0pt;line-height: 112%;text-align: justify;">"РИТЪМ-4-ТБ" ООД, гр.Стара Загора, вписано в търговския регистър при Агенцията по вписванията с ЕИК 123655865, регистрирано на пазара на балансираща енергия като търговец, притежаващ Лицензия за търговия с електрическа енергия №Л-370-15/21.11.2011г. и Разширение на Лицензия за координатор на балансираща група № И1-Л-370/17.12.2012г. с идентификационен номер на търговски участник „32X001100100382L“ със седалище и адрес на управление гр. Стара Загора, ул. „Свети Княз Борис” № 93, ет. 9, представлявано от Руси Илчев Данев – Управител, наричано по-долу за краткост “ТЪРГОВЕЦ”</p>
<h4 style="padding-top: 9pt;text-indent: 0pt;text-align: center;">и</h4>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<p style="padding-top: 6pt;padding-left: 7pt;text-indent: 0pt;text-align: left;">{COMPANY}, гр./с. {CITY}, вписано в търговския регистър при Агенцията по вписванията с ЕИК {EIK}, със седалище и адрес на управление гр./с.{ADDRESS}, представлявано от {NAME}–………..........…......................…, наричано по-долу за краткост “КЛИЕНТ”, всяка поотделно наричана също така „Страна”, а заедно наричани “Страни”, на основание чл.100, ал.1 от Закона за енергетиката (ЗЕ), Правилата за търговия с електрическа енергия (ПТЕЕ), се сключи настоящият договор (наричан по-долу „Договорът”) за следното:</p>
<p class="s1" style="padding-top: 11pt;text-indent: 0pt;text-align: center;">РАЗДЕЛ І. ПРЕДМЕТ НА ДОГОВОРА</p>
<ol id="l1">
   <ol id="l2">
      <li data-list-text="1.1.">
         1.1. ТЪРГОВЕЦЪТ продава активна нетна електрическа енергия на КЛИЕНТА, а КЛИЕНТЪТ купува и заплаща електрическата енергия в сроковете, по реда и при условията, описани по-долу в този Договор и съгласно приложенията към него.
      </li>
      <li data-list-text="1.2.">
         1.2. ТЪРГОВЕЦЪТ в качеството си на координатор на стандартна балансираща група, регистрира КЛИЕНТА като член на балансиращата група, съгласно ПТЕЕ и поема за своя сметка балансирането на присъединените към него от страна на КЛИЕНТА обекти.
      </li>
      <li data-list-text="1.3.">
         1.3. ТЪРГОВЕЦЪТ изготвя за своя сметка прогнозиране на потреблението на присъединените към него обекти на КЛИЕНТА, описани в Приложение №2 на този договор.
      </li>
      <li data-list-text="1.4.">
         1.4. ТЪРГОВЕЦЪТ извършва при необходимост регистрацията на КЛИЕНТА на либерализирания пазар на електрическа енергия, като подготвя всички необходими в тази връзка документи и осъществява  съответните  действия  за  успешната  му  регистрация.  С  настоящия  договор КЛИЕНТЪТ упълномощава ТЪРГОВЕЦА при необходимост да сключи от негово име и за негова сметка изискуемите съгласно Закона за енергетиката и ПТЕЕ договори за достъп и пренос през електроразпределителната мрежа със съответния мрежови оператор, както и да сключи договор с
         доставчик от последна инстанция. КЛИЕНТЪТ упълномощава ТЪРГОВЕЦА при необходимост да го представлява пред мрежовия оператор във връзка със смяна/прехвърляне на партида/и, увеличаване/намаляване на мощност, възстановяване на депозити, актуализация на клиентски данни, подаване на документи за промяна в статут на обект/и.
      </li>
   </ol>
</ol>
<p style="padding-top: 2pt;padding-left: 6pt;text-indent: 0pt;line-height: 114%;text-align: justify;"></p>
<p class="s1" style="padding-top: 11pt;padding-left: 6pt;text-indent: 0pt;text-align: center;">РАЗДЕЛ ІІ. ВЛИЗАНЕ В СИЛА И СРОК НА ДЕЙСТВИЕ НА ДОГОВОРА</p>
<p style="text-indent: 0pt;text-align: left;"><span></span></p>
<p></p>
<ol id="l3">
   <ol id="l4">
      <li data-list-text="2.1.">
         2.1. Този договор влиза в сила в момента на подписването му и от двете страни и е със срок на действие една календарна година от 00:00 ч. на първото число на месеца, следващ месеца, в който е извършена регистрацията на КЛИЕНТА като член на балансиращата група, на която координатор е ТЪРГОВЕЦЪТ.
      </li>
      <li data-list-text="2.2.">
         2.2. За този договор една календарна година е равна на триста шестдесет и пет (365) дни.
      </li>
      <li data-list-text="2.3.">
         2.3. Ако тридесет (30) календарни дни преди изтичане срока на договора по т. 2.1. никоя от страните не заяви писмено желание за прекратяване или друга промяна на условията по него, то същият се счита за продължен с една календарна година при същите условия.
      </li>
   </ol>
</ol>
<p class="s1" style="padding-top: 1pt;padding-left: 6pt;text-indent: 0pt;text-align: center;">РАЗДЕЛ III. ЦЕНИ И ПЛАЩАНИЯ</p>
<ol id="l5">
   <ol id="l6">
      <li data-list-text="3.1.">
         3.1. Количества и цени.
         <ol id="l7">
            <li data-list-text="3.1.1.">
               3.1.1. Страните се споразумяват, че за целия срок на договора цената за един МВтч активна електрическа енергия сe получава от клиринговата почасова цена на Българска независима енергийна борса (БНЕБ) в сегмент "Пазар ден напред", плюс добавка от ...........лв./МВтч.,
               умножена по съответното потребление в същия час, като количествата са отчетени съгласно средствата за търговско измерване в точките на присъединяване на КЛИЕНТА и/или неговия
               товаров профил.
            </li>
            <li data-list-text="3.1.2.">
               3.1.2. Цената по т. 3.1.1. не подлежи на промяна за срока на действие на този договор, освен с изричното писмено съгласие на двете Страни.
            </li>
            <li data-list-text="3.1.3.">
               3.1.3. Върху така определената цена в т.3.1.1., по-горе, се начисляват допълнително и дължат законосъобразните размери на Акциз, цена за „Задължения към обществото“ и Данък добавена стойност (ДДС) и/или други такси и допълнителни задължения, които могат да възникнат с промяна в законодателството и/или с решение на съответния регулаторен орган.
            </li>
            <li data-list-text="3.1.4.">
               3.1.4. КЛИЕНТЪТ заплаща на съответния оператор и/или собственик на мрежата, към която са присъединени обектите му, цената за ползване на мрежата и мрежовите услуги. В случаите на комбинирана доставка, КЛИЕНТЪТ заплаща на ТЪРГОВЕЦА и всички приложими цени за мрежови услуги, съгласно действащото законодателство.
            </li>
         </ol>
      </li>
      <li data-list-text="3.2.">
         3.2. Фактуриране и плащания.
         <ol id="l8">
            <li data-list-text="3.2.1.">
               3.2.1. Фактурирането и плащането се извършват на базата на предоставените от мрежовия оператор валидирани стойности от средствата за търговско измерване (СТИ) за съответния период.
            </li>
            <li data-list-text="3.2.2.">
               3.2.2. Страните се съгласяват, че ТЪРГОВЕЦЪТ фактурира на КЛИЕНТА и сумите, вследствие на установено неизмерване, непълно или неточно измерване, които Мрежовият оператор може да претендира от КЛИЕНТА, заедно с всички дължими добавки и преки и косвени данъци.
            </li>
            <li data-list-text="3.2.3.">
               3.2.3. До девето (9-то) число на следващия месец, ТЪРГОВЕЦЪТ издава и изпраща на КЛИЕНТА фактура-оригинал за доставената електрическа енергия за календарен месец с дата последния ден на месеца на доставка. Фактурата се издава в съответствие с  изискванията на действащото законодателство в Република България. КЛИЕНТЪТ се задължава да заплати дължимата сума в срок до двадесетo (20-то) число в месеца, в който е издадена. Фактурата се изпраща на електронен адрес, посочен от КЛИЕНТА. Неполучаването на изпратеното съобщение с издадената фактура не освобождава КЛИЕНТА от задължението да заплати дължимите суми в срок, заедно с всички последици при неплащане и/или закъсняло плащане.</p>
            </li>
            <li data-list-text="3.2.4.">
               3.2.4. В случай на прекъсване по реда на т. 3.3.3 и възстановяване на захранването по реда на т. 3.3.4., ТЪРГОВЕЦЪТ фактурира на КЛИЕНТА заплатените такси по ценоразпис на съответния мрежови оператор, които КЛИЕНТЪТ е длъжен да заплати.
               <p style="text-indent: 0pt;text-align: left;"><span></span></p>
               <p></p>
            </li>
            <li data-list-text="3.2.5.">
               3.2.5. В случай на самоволно възстановяване на захранването от страна на КЛИЕНТА, прекъснато по заявка на ТЪРГОВЕЦА, доставената електрическа енергия се отчита и фактурира при получаване на данните от средствата за търговско измерване и се заплащат от КЛИЕНТА поцената за недостиг, определена от ЕСО за съответния период на сетълмент, като върху нея допълнително се надчисляват всички дължими суми да мрежови услуги, добавки и данъци.
            </li>
            <li data-list-text="3.2.6.">
               3.2.6. КЛИЕНТЪТ
               заплаща дължимите суми по издадени от ТЪРГОВЕЦА фактури по следните начини:
               <ul id="l9" style="list-style: none;">
                  <li data-list-text="">
                     <p class="s1" style="padding-left: 41pt;text-indent: -17pt;line-height: 14pt;text-align: justify;">а) В брой – в лева, на каса в клоновата мрежа на Български Пощи ЕАД.</p>
                  </li>
                  <li data-list-text="">
                     <p class="s1" style="padding-left: 42pt;text-indent: -18pt;text-align: justify;">б) По банков път – в лева, на сметката на ТЪРГОВЕЦА (ДОСТАВЧИКА), вписана във фактурата.</p>
                  </li>
                  <li data-list-text="">
                     <p class="s1" style="padding-left: 42pt;text-indent: -18pt;text-align: justify;">в) Чрез директен дебит – в лева, от обслужващата банка на КЛИЕНТА. За целта предварително КЛИЕНТЪТ представя на ТЪРГОВЕЦА съгласие за директен дебит, заверено от обслужващата го банка.</p>
                  </li>
                  <li data-list-text="">
                     <p class="s1" style="padding-left: 41pt;text-indent: -17pt;line-height: 14pt;text-align: justify;">г) Онлайн - с дебитна/кредитна карта в клиентския портал: http://my.ritam4tb.com.</p>
                  </li>
               </ul>
            </li>
         </ol>
      </li>
      <li data-list-text="3.3.">
         3.3. Отговорности и санкции.
         <ol id="l10">
            <li data-list-text="3.3.1.">
               3.3.1. Страните не си дължат обезщетения в случай на въвеждане на ограничителен режим (чл.72 от ЗЕ), временно прекъсване на пазара или ограничаване на количествата за доставка от страна на Оператора на ЕЕС (ЕСО ЕАД) по реда и в случаите, указани в ПТЕЕ и Правилата за управление на електроенергийната система.
            </li>
            <li data-list-text="3.3.2.">
               3.3.2. Върху размера на просрочените от КЛИЕНТА плащания за периода от датата на падежа до датата на плащане се дължи законната лихва в размер на основният лихвен процент (ОЛП) на БНБ плюс десет пункта.
            </li>
            <li data-list-text="3.3.3.">
               3.3.3. В случай на забавено плащане повече от 30 /тридесет/ календарни дни ТЪРГОВЕЦЪТ има право да поиска от съответния мрежови оператор прекъсване на захранването на КЛИЕНТА на основание чл. 123 от Закона за енергетиката и съгласно сключените с мрежовите оператори рамкови договори.
            </li>
            <li data-list-text="3.3.4.">
               3.3.4. След заплащане на просрочените задължения от страна на КЛИЕНТА, в това число всички дължими суми по т. 3.2.4 от настоящия договор, ТЪРГОВЕЦЪТ се задължава да поиска от съответния мрежови оператор да възстанови захранването на КЛИЕНТА.
            </li>
         </ol>
      </li>
   </ol>
</ol>
<p class="s1" style="padding-top: 1pt;text-indent: 0pt;text-align: center;">РАЗДЕЛ ІV. ПРАВА И ЗАДЪЛЖЕНИЯ НА СТРАНИТЕ</p>
<ol id="l11">
   <ol id="l12">
      <li data-list-text="4.1.">
         4.1. Права и задължения на ТЪРГОВЕЦА.
         <ol id="l13">
            <li data-list-text="4.1.1.">
               4.1.1. За продадените и доставени количества електрическа енергия до обектите на КЛИЕНТА, ТЪРГОВЕЦЪТ има право да получи уговорената цена, съгласно този договор.
            </li>
            <li data-list-text="4.1.2.">
               4.1.2. ТЪРГОВЕЦЪТ се задължава да продаде и да обезпечи в място на доставяне количество електрическа енергия в съответствие с нуждите на КЛИЕНТА. Мястото/местата на доставка са описани в Приложение №2 към настоящия договор.
            </li>
            <li data-list-text="4.1.3.">
               4.1.3. ТЪРГОВЕЦЪТ фактурира доставената електрическа енергия, съгласно предоставените от мрежовия оператор валидирани стойности от средствата за търговско измерване (СТИ), в срока по т. 3.2.3.
            </li>
            <li data-list-text="4.1.4.">
               4.1.4. ТЪРГОВЕЦЪТ се задължава да предостави на КЛИЕНТА всяка поискана информация, данни или документи в договорените срокове.
            </li>
            <li data-list-text="4.1.5.">
               4.1.5. ТЪРГОВЕЦЪТ не носи отговорност за причинени на КЛИЕНТА вреди, вследствие на отклонения от нормативните изисквания за качество на доставяната електрическа енергия,
               настъпили по причина на неизправност на съоръженията за пренос и/или разпределение на електрическа енергия, аварийни и планови прекъсвания, оперативни превключвания и други.
            </li>
         </ol>
      </li>
      <li data-list-text="4.2.">
         4.2. Права и задължения на КЛИЕНТА
         <ol id="l14">
            <li data-list-text="4.2.1.">
               4.2.1. КЛИЕНТЪТ има право да получи необходимите му количества активна електрическа енергия, като се задължава да ги приеме в мястото/местата на доставка съгласно Приложение 2.
            </li>
            <li data-list-text="4.2.2.">
               4.2.2. КЛИЕНТЪТ се задължава да заплати на ТЪРГОВЕЦА цялата доставена му активна електрическа  енергия  на  база  предоставените  от  мрежовия  оператор  към  ТЪРГОВЕЦА валидирани стойности от средствата за търговско измерване (СТИ).
               <p style="text-indent: 0pt;text-align: left;"><span></span></p>
               <p></p>
            </li>
            <li data-list-text="4.2.3.">
               4.2.3. КЛИЕНТЪТ се задължава да предостави на ТЪРГОВЕЦА необходимите за правилно изпълнение на този договор информация, данни или документи в посочените срокове.
            </li>
            <li data-list-text="4.2.4.">
               4.2.4. КЛИЕНТЪТ прехвърля на ТЪРГОВЕЦА отговорността за балансиране на всички свои обекти, описани в Приложение №2, като не дължи допълнително възнаграждение за това.
            </li>
            <li data-list-text="4.2.5.">
               4.2.5. КЛИЕНТЪТ приема да съдейства на ТЪРГОВЕЦА с предоставяне на необходимата информация,  позволяваща  изготвянето  на  прогноза  за  потреблението  му  и евентуалните отклонения, от които възниква отговорност за балансиране от страна на ТЪРГОВЕЦА и да оказва необходимото съдействие на ТЪРГОВЕЦА за извършване на регистрацията на пазара на електрическа енергия, като всички такси, депозити и други такива разходи, изисквани от съответния оператор на мрежата са за сметка на КЛИЕНТА.
            </li>
            <li data-list-text="4.2.6.">
               4.2.6. КЛИЕНТЪТ е длъжен да уведоми ТЪРГОВЕЦА в договорените срокове при невъзможност или забавяне на изпълнение на задълженията по настоящия договор, промяна на данните по регистрацията му в Търговския регистър, промяна на статута на обектите описани в Приложение №2, промяна на лицата по т.5.2. от настоящия договор и други, касаещи отношенията по настоящия договор.
            </li>
         </ol>
      </li>
   </ol>
</ol>
<p class="s1" style="padding-top: 1pt;padding-left: 6pt;text-indent: 0pt;text-align: center;">РАЗДЕЛ V. ДОСТАВКА И ОБМЕН НА ИНФОРМАЦИЯ</p>
<ol id="l15">
   <ol id="l16">
      <li data-list-text="5.1.">
         5.1. Доставка и товарови графици.
         <ol id="l17">
            <li data-list-text="5.1.1.">
               5.1.1. ТЪРГОВЕЦЪТ изготвя прогнозните дневни почасови товарови графици за потреблението на КЛИЕНТА за обектите, описани в Приложение №2. Дневният график за доставка обхваща 24-часов период по българско часово време, съответно за 23- и 25-часов период в дните на преминаване към лятно и зимно часово време. За тази дейност ТЪРГОВЕЦЪТ няма право на допълнително възнаграждение.
            </li>
            <li data-list-text="5.1.2.">
               5.1.2. ТЪРГОВЕЦЪТ  носи  отговорност  за  всички  възникнали  небаланси  породени  от потреблението на КЛИЕНТА.
            </li>
            <li data-list-text="5.1.3.">
               5.1.3. КЛИЕНТЪТ приема да положи нормални усилия да уведоми ТЪРГОВЕЦА за обстоятелства, породени от промяна в дейността му, планови ремонти или други причини, които водят до значителни (над  петдесет  процента  от  среднодневното  му  потребление  за  дадения  ден) отклонения в потреблението му за периода на действие на този договор. В случай на очаквано изменение или отклонение на своето потребление на активна електрическа енергия, КЛИЕНТЪТ информира ТЪРГОВЕЦА, за да може последният да отрази съответните изменения в прогнозните графици на снабдяване по отношение на потреблението на обектите, описани в Приложение №2.
            </li>
         </ol>
      </li>
      <li data-list-text="5.2.">
         5.2. Обмен на информация.
         <ol id="l18">
            <li data-list-text="5.2.1.">
               5.2.1. За предоставянето и обмена на техническа и друга информация, с изключение на подписване на допълнителни споразумения, изменения и прекратяване, са упълномощени лицата, описани в Приложение №1 „Оправомощени лица” - неразделна част от този Договор.
            </li>
            <li data-list-text="5.2.2.">
               5.2.2. Всяка промяна или допълнение към съдържанието и допълнителните споразумения към Договора, както е определено в настоящия Договор, включително и прекратяване и/или продължение на Договора, се подписва от лицето, представляващо по закон съответната Страна, съгласно действащото законодателство, освен в случаите когато изрично е посочено как ще бъде извършена съответната промяна или допълнение, прекратяване или продължаване на договора.
            </li>
            <li data-list-text="5.2.3.">
               5.2.3. В случай на промени на лицата по т.5.2.1. и т.5.2.2., Страните ще се информират за промяната писмено в срок до 7 (седем) дни преди влизане в сила на промяната.
            </li>
         </ol>
      </li>
      <li data-list-text="5.3.">
         5.3. Поверителност на информацията.
         <p style="text-indent: 0pt;text-align: left;"><span></span></p>
         <p></p>
         <ol id="l19">
            <li data-list-text="5.3.1.">
               5.3.1. Всяка една от Страните по настоящия Договор е задължена да не разкрива условията на Договора пред трета страна, освен в случайте с предварителното писмено съгласие на другата страна, като заинтересованата страна се задължава да положи усилия за ограничаване на разкриването на информацията в рамките на разрешеното от закона и освен ако е публично достъпна.
            </li>
            <li data-list-text="5.3.2.">
               5.3.2. Всяка от Страните по Договора е длъжна да спазва изискването за поверителност на информацията до две (2) години след изтичане срока на действие на настоящия Договор.
            </li>
         </ol>
      </li>
   </ol>
</ol>
<p class="s1" style="padding-top: 11pt;padding-left: 6pt;text-indent: 0pt;text-align: center;">РАЗДЕЛ VІ. НЕПРЕОДОЛИМА СИЛА</p>
<p class="s1" style="padding-top: 10pt;padding-left: 6pt;text-indent: 0pt;line-height: 114%;text-align: justify;"></p>
<ol id="l20">
   <ol id="l21">
      <li data-list-text="6.1.">
        6.1. Обстоятелствата на непреодолима сила са непредвидени и непредотвратими събития от извънреден характер, извън контрола на страните по договора като, но неограничаващи се само до:  война,  саботаж,  въстание,  бунт,  експлозия,  пожар,  природни  бедствия  (наводнения, земетресения, гръм, буря, свличания или срутвания, торнадо, обледеняване и др.)
      </li>
      <li data-list-text="6.2.">
         6.2. При наличие на обстоятелства на непреодолима сила двете Страни се задължават взаимно да се уведомят във възможно най-кратък срок, но не по-дълъг от 10 (десет) дни от началото от възникването, както и да положат всички необходими усилия за намаляване или предотвратяване на вредите и последствията от тези обстоятелства.
      </li>
      <li data-list-text="6.3.">
         6.3. В случай, че страната, позоваваща се на непреодолима сила, не изпрати уведомлението в срока по т.6.2., същата губи право да се позовава на непреодолима сила.
      </li>
      <li data-list-text="6.4.">
         6.4. За времето на непреодолима сила двустранно се спира изпълнението на задълженията по договора.
      </li>
   </ol>
</ol>
<p class="s1" style="padding-top: 11pt;padding-left: 6pt;text-indent: 0pt;text-align: center;">РАЗДЕЛ VІI. ПРЕКРАТЯВАНЕ НА ДОГОВОРА</p>
<ol id="l22">
   <ol id="l23">
      <li data-list-text="7.1.">
         7.1. Договорът може да бъде прекратен в следните случаи:
         <ol id="l24">
            <li data-list-text="7.1.1.">
               7.1.1. С изтичане срока на Договора.
            </li>
            <li data-list-text="7.1.2.">
               7.1.2. По взаимно писмено съгласие между Страните.
            </li>
            <li data-list-text="7.1.3.">
               7.1.3. С пет (5) дневно писмено предизвестие от ТЪРГОВЕЦА, в случай на неизпълнение на задължението на КЛИЕНТА за заплащане на дължимата цена за доставената активна електрическа енергия. Ако в този 5-дневен срок КЛИЕНТЪТ заплати всички дължими суми за доставената и отчетена електрическа енергия, ТЪРГОВЕЦЪТ има право да не прекрати договора.
            </li>
            <li data-list-text="7.1.4.">
               7.1.4. С шестдесет дневно (60) писмено предизвестие от ТЪРГОВЕЦА, в случай на невъзможност да изпълни договора до срока на неговото изтичане.
            </li>
            <li data-list-text="7.1.5.">
               7.1.5. Едностранно, по искане на КЛИЕНТА с отправянето до ТЪРГОВЕЦА писмено предизвестие (в свободен текст), не по-малко от тридесет (30) календарни дни от желаната дата на прекратяване на  договора.  При  предсрочно  прекратяване  на  подписания  между  страните  договор, иницииращата прекратяването СТРАНА, дължи обезщетение на другата СТРАНА, равно на крайната стойността на последно издадена фактура за реално потребена и отчетена електрическа енергия от КЛИЕНТА с включено ДДС. Ако няма реална консумация в последно издадената и получена фактура, се взима предходната такава, в която е отчетена реална консумация.
            </li>
         </ol>
      </li>
   </ol>
</ol>
<p style="padding-top: 2pt;padding-left: 6pt;text-indent: 0pt;line-height: 114%;text-align: justify;"></p>
<p class="s1" style="padding-top: 11pt;padding-left: 6pt;text-indent: 0pt;text-align: center;">РАЗДЕЛ VIII. РЕШАВАНЕ НА СПОРОВЕ</p>
<p style="text-indent: 0pt;text-align: left;"><span></span></p>
<p></p>
<ol id="l25">
   <ol id="l26">
      <li data-list-text="8.1.">
         8.1. Всички спорове, породени по този Договор или отнасящи се до него, включително спорове, отнасящи се до неговото тълкуване, недействителност, изпълнение или прекратяване, или приспособяването му към нововъзникналите обстоятелства, се уреждат чрез преговори между Страните.
      </li>
      <li data-list-text="8.2.">
         8.2. Ако не бъде постигнато споразумение, спорът ще бъде отнесен към компетентния съд в Република България. Отнасянето на спора за разрешаване от съда не е причина за прекратяване изпълнението на другите задължения по този Договор.
      </li>
      <li data-list-text="8.3.">
         8.3. За всички, неуредени или противоречащи на Българското законодателство точки и въпроси в този Договор, се прилагат разпоредбите на действащото в Република България законодателство.
      </li>
   </ol>
</ol>
<p class="s1" style="padding-top: 11pt;text-indent: 0pt;padding-left: 6pt;text-align: center;">РАЗДЕЛ IX. ЗАЩИТА НА ЛИЧНИТЕ ДАННИ</p>
<ol id="l27">
   <ol id="l28">
      <li data-list-text="9.1.">
         9.1. ТЪРГОВЕЦЪТ е администратор на лични данни по смисъла на Закона за защита на личните данни, като обработва лични данни самостоятелно или чрез възлагане на обработващи данните и в тази връзка осигурява спазването на изискванията на Регламент (ЕС) 2016/679 на Европейския парламент и на съвета от 27 април 2016 г. относно защитата на физическите лица във връзка с обработването на лични данни и относно свободното движение на такива данни и за отмяна на Директива 95/46/ЕО /Общ регламент относно защитата на данните/.
      </li>
      <li data-list-text="9.2.">
         9.2. ТЪРГОВЕЦЪТ И КЛИЕНТЪТ се задължават да спазват принципите за конфиденциалност на личните данни, станали им известни при изпълнение на настоящия договор, няма да ги разпространяват и няма да ги използват за други цели, освен за прякото изпълнение на договора.
      </li>
   </ol>
</ol>
<p class="s1" style="padding-top: 11pt;padding-left: 7pt;text-indent: 0pt;text-align: center;">РАЗДЕЛ Х. ЗАКЛЮЧИТЕЛНИ РАЗПОРЕДБИ</p>
<ol id="l29">
   <ol id="l30">
      <li data-list-text="10.1.">
         10.1. В случай, че между страните има сключени предходни договори и/или анекси към тях, същите се считат за отменени изцяло с подписването на настоящия договор.
      </li>
      <li data-list-text="10.2.">
         10.2. В случай на нововъзникнали правни ограничения или промяна в нормативната уредба, Страните се задължават чрез допълнително споразумение да приведат настоящия договор в съответствие с новите норми.
      </li>
   </ol>
</ol>
<div style="padding-left: 30px;margin-left: 2.90rem">
  <p class="s1" style="padding-left: 6pt;text-indent: 0pt;line-height: 114%;text-align: justify;">Този Договор се състои от шест (6) страници и се изготви и подписа в два (2) оригинални екземпляра на български език – по един за всяка от Страните. Неразделна част от договора са двете Приложения:</p>
  <p style="padding-left: 6pt;text-indent: 0pt;line-height: 13pt;text-align: justify;">№1- оправомощени лица за информация и контакт;</p>
  <p style="padding-top: 2pt;padding-left: 6pt;text-indent: 0pt;line-height: 113%;text-align: justify;">№2- списък на местата на доставка с обектите на КЛИЕНТА, за които се доставя електрическа енергия по настоящия договор.</p>
  <p style="text-indent: 0pt;text-align: left;"><br></p>
  <table>
    <tbody>
      <tr>
        <td>
          ТЪРГОВЕЦ ....................................
        </td>
        <td>
          КЛИЕНТ...........................................
        </td>
      </tr>
      <tr>
        <td>
          /Руси Данев - Управител/
        </td> 
        <td>
          /{NAME}/
        </td>
      </tr>
      <tr>
        <td>
          ЗА ТЪРГОВЕЦА
        </td> 
        <td>
          ЗА КЛИЕНТА
        </td>
      </tr>
    </tbody>
  </table>
</div>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<p style="text-indent: 0pt;line-height: 1pt;text-align: left;"></p>
`;

const table1 = `
<b class="s4" style="text-indent: -6pt;line-height: 114%;text-align: center;">ПРИЛОЖЕНИЕ № 1 – Оправомощени лица за информация и контакт по Договор № ELCA……....…../{DATE}г.</b>
<br/>
<p style="padding-left: 6pt;text-indent: 0pt;text-align: left;">От страна на ТЪРГОВЕЦА:</p>
<table style="border-collapse:collapse;margin-left:6.01pt" cellspacing="0">
   <tbody>
      <tr style="height:31pt">
         <td style="width:21pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s5" style="padding-left: 2pt;text-indent: 0pt;line-height: 14pt;text-align: center;">№</p>
         </td>
         <td style="width:84pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s5" style="padding-top: 8pt;padding-left: 29pt;padding-right: 29pt;text-indent: 0pt;text-align: center;">Име</p>
         </td>
         <td style="width:120pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s5" style="padding-top: 8pt;padding-left: 49pt;text-indent: 0pt;text-align: left;">Длъжност</p>
         </td>
         <td style="width:101pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s5" style="padding-top: 2pt;padding-left: 29pt;padding-right: 19pt;text-indent: -1pt;text-align: left;">Телефон / Мобилен</p>
         </td>
         <td style="width:163pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s5" style="padding-top: 8pt;padding-left: 24pt;padding-right: 24pt;text-indent: 0pt;text-align: center;">e-mail адрес</p>
         </td>
      </tr>
      <tr style="height:43pt">
         <td style="width:21pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
            <p class="s6" style="text-indent: 0pt;text-align: center;">1</p>
         </td>
         <td style="width:84pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:120pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-top: 5pt;padding-left: 31pt;text-indent: -20pt;line-height: 114%;text-align: center;">Експерт Продажби ел. енергия</p>
         </td>
         <td style="width:101pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:163pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
      </tr>
      <tr style="height:34pt">
         <td style="width:21pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-top: 9pt;text-indent: 0pt;text-align: center;">2</p>
         </td>
         <td style="width:84pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-top: 1pt;padding-left: 28pt;padding-right: 18pt;text-indent: 5pt;line-height: 114%;text-align: left;">Илка Данева</p>
         </td>
         <td style="width:120pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-top: 1pt;padding-left: 23pt;padding-right: 22pt;text-indent: 0pt;text-align: center;">Изпълнителен Директор</p>
         </td>
         <td style="width:101pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-top: 10pt;padding-left: 8pt;padding-right: 8pt;text-indent: 0pt;text-align: center;">+359 29 807 016</p>
         </td>
         <td style="width:163pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="padding-top: 8pt;padding-left: 24pt;padding-right: 24pt;text-indent: 0pt;text-align: center;"><p class="s7">trade@ritam-bg.com</p></p>
         </td>
      </tr>
      <tr style="height:35pt">
         <td style="width:21pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-top: 10pt;text-indent: 0pt;text-align: center;">3</p>
         </td>
         <td style="width:84pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-top: 1pt;padding-left: 23pt;padding-right: 21pt;text-indent: 0pt;line-height: 114%;text-align: left;">Йордан Вучков</p>
         </td>
         <td style="width:120pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-top: 1pt;text-indent: 5pt;line-height: 114%;text-align: center;">Експерт товарови графици и прогнози</p>
         </td>
         <td style="width:101pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-top: 1pt;text-indent: 0pt;text-align: center;">+359 29 807 016 /</p>
            <p class="s6" style="padding-top: 2pt;text-indent: 0pt;text-align: center;">+359 889 665 524</p>
         </td>
         <td style="width:163pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="padding-top: 9pt;padding-left: 24pt;padding-right: 24pt;text-indent: 0pt;text-align: center;"><p class="s7">trade@ritam-bg.com</p></p>
         </td>
      </tr>
      <tr style="height:34pt">
         <td style="width:21pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-top: 9pt;text-indent: 0pt;text-align: center;">4</p>
         </td>
         <td style="width:84pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-top: 1pt;padding-left: 18pt;padding-right: 18pt;text-indent: 4pt;line-height: 114%;text-align: left;">Атанас Захариев</p>
         </td>
         <td style="width:120pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-top: 1pt;text-indent: -12pt;line-height: 114%;text-align: center;">Фактуриране и Плащания</p>
         </td>
         <td style="width:101pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-top: 1pt;text-indent: 0pt;text-align: center;">+359 42 600 945 /</p>
            <p class="s6" style="padding-top: 2pt;text-indent: 0pt;text-align: center;">+359 887 727 190</p>
         </td>
         <td style="width:163pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="padding-top: 8pt;padding-left: 24pt;padding-right: 24pt;text-indent: 0pt;text-align: center;"><p class="s7">trade@ritam-bg.com</p></p>
         </td>
      </tr>
      <tr style="height:34pt">
         <td style="width:21pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-top: 9pt;text-indent: 0pt;text-align: center;">5</p>
         </td>
         <td style="width:84pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-left: 15pt;text-indent: 7pt;line-height: 114%;text-align: left;">Галина Георгиева</p>
         </td>
         <td style="width:120pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-top: 8pt;text-indent: 0pt;text-align: center;">Счетоводител</p>
         </td>
         <td style="width:101pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-top: 8pt;padding-left: 8pt;padding-right: 8pt;text-indent: 0pt;text-align: center;">+359 42 600 454</p>
         </td>
         <td style="width:163pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="padding-top: 8pt;padding-left: 24pt;padding-right: 24pt;text-indent: 0pt;text-align: center;"><p class="s7">account@ritam-bg.com</p></p>
         </td>
      </tr>
   </tbody>
</table>
<p style="padding-left: 6pt;text-indent: 0pt;text-align: left;">От страна на КЛИЕНТА:</p>
<table style="border-collapse:collapse;margin-left:6.01pt" cellspacing="0">
   <tbody>
      <tr style="height:36pt">
         <td style="width:20pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s5" style="padding-left: 5pt;text-indent: 0pt;line-height: 14pt;text-align: left;">№</p>
         </td>
         <td style="width:85pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s5" style="padding-top: 10pt;padding-left: 20pt;text-indent: 0pt;text-align: left;">Име</p>
         </td>
         <td style="width:121pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s5" style="padding-top: 10pt;padding-left: 32pt;text-indent: 0pt;text-align: left;">Длъжност</p>
         </td>
         <td style="width:107pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s5" style="padding-top: 4pt;padding-left: 29pt;padding-right: 26pt;text-indent: -1pt;text-align: left;">Телефон / Мобилен</p>
         </td>
         <td style="width:156pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s5" style="padding-top: 10pt;padding-left: 45pt;text-indent: 0pt;text-align: left;">e-mail адрес</p>
         </td>
      </tr>
      <tr style="height:42pt">
         <td style="width:20pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
            <p class="s6" style="padding-left: 6pt;text-indent: 0pt;text-align: left;">1</p>
         </td>
         <td style="width:85pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:121pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-top: 4pt;text-indent: 6pt;line-height: 114%;text-align: center;">Управител/ Изп.Директор</p>
         </td>
         <td style="width:107pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:156pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
      </tr>
      <tr style="height:35pt">
         <td style="width:20pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-top: 9pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">2</p>
         </td>
         <td style="width:85pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:121pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-top: 9pt;text-indent: 0pt;text-align: center;">Счетоводител</p>
         </td>
         <td style="width:107pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:156pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
      </tr>
      <tr style="height:34pt">
         <td style="width:20pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-top: 9pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">3</p>
         </td>
         <td style="width:85pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:121pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="text-indent: 0pt;text-align: center;">Лице за контакт</p>
         </td>
         <td style="width:107pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:156pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
      </tr>
      <tr style="height:34pt">
         <td style="width:20pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-top: 9pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">4</p>
         </td>
         <td style="width:85pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:121pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p class="s6" style="padding-top: 8pt;text-indent: 0pt;text-align: center;">Техническо лице</p>
         </td>
         <td style="width:107pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:156pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
      </tr>
   </tbody>
</table>
<p style="text-indent: 0pt;text-align: left;"></p>
<p style="padding-left: 6pt;">Фактури ще се изпращат от: invoice@ritam-bg.com или trade@ritam-bg.com</p>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<p class="s9" style="padding-left: 6pt;text-indent: 0pt;line-height: 117%;text-align: left;">Забележка: Техническото лице - енергетик или друга длъжност, която технически поддържа обектите на КЛИЕНТА.</p>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<p class="s8" style="padding-left: 6pt;text-indent: 0pt;text-align: left;">Фактури ще се получават на: {OTHER_EMAIL}</p>
<p style="text-indent: 0pt;text-align: left;"><span></span></p>
<p></p>
<p style="text-indent: 0pt;text-align: right;"><br></p>
<p style="padding-left: 5pt;text-indent: 0pt;line-height: 1pt;text-align: right;"></p>
<p style="text-indent: 0pt;text-align: right;"><span></span></p>
<p></p>
`;

const table2 = `
<b class="s4" style="padding-top: 4pt;line-height: 114%;text-align: center;">ПРИЛОЖЕНИЕ № 2 – Списък на местата на доставката с обектите на КЛИЕНТА по Договор № ELCA…………..…/{DATE}г.</b>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<table style="border-collapse:collapse;margin-left:7.01pt" cellspacing="0">
   <tbody>
      <tr style="height:84pt">
         <td style="width:128pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:2pt;border-bottom-color:#666666;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
            <p class="s5" style="padding-left: 7pt;text-indent: 0pt;text-align: left;">Наименование обект</p>
         </td>
         <td style="width:105pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:2pt;border-bottom-color:#666666;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
            <p class="s5" style="padding-left: 7pt;padding-right: 6pt;text-indent: 0pt;text-align: center;">Абонатен номер</p>
            <p class="s5" style="padding-top: 6pt;padding-left: 7pt;padding-right: 6pt;text-indent: 0pt;text-align: center;">/ ИТН</p>
         </td>
         <td style="width:137pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:2pt;border-bottom-color:#666666;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
            <p class="s5" style="padding-left: 25pt;text-indent: 0pt;text-align: left;">Адрес на обекта</p>
         </td>
         <td style="width:58pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:2pt;border-bottom-color:#666666;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
            <p class="s5" style="padding-left: 16pt;text-indent: 0pt;text-align: left;">ЕРП</p>
         </td>
         <td style="width:101pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:2pt;border-bottom-color:#666666;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
            <p class="s5" style="padding-left: 22pt;padding-right: 21pt;text-indent: 3pt;line-height: 150%;text-align: left;">Настоящ доставчик</p>
         </td>
         <td style="width:93pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:2pt;border-bottom-color:#666666;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
            <p class="s5" style="padding-left: 18pt;padding-right: 17pt;text-indent: 0pt;line-height: 114%;text-align: center;">Статус на свободния пазар</p>
         </td>
         <td style="width:98pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:2pt;border-bottom-color:#666666;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p class="s5" style="padding-left: 19pt;padding-right: 18pt;text-indent: 0pt;text-align: center;">Прогнозна</p>
            <p style="text-indent: 0pt;text-align: left;"><br></p>
            <p class="s5" style="padding-left: 11pt;padding-right: 10pt;text-indent: 0pt;line-height: 114%;text-align: center;">годишна консумация в МВтч</p>
         </td>
      </tr>
      {MEASURE_POINTS}
      <tr style="height:64pt">
         <td style="width:128pt;border-top-style:solid;border-top-width:2pt;border-top-color:#666666;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:105pt;border-top-style:solid;border-top-width:2pt;border-top-color:#666666;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:137pt;border-top-style:solid;border-top-width:2pt;border-top-color:#666666;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:58pt;border-top-style:solid;border-top-width:2pt;border-top-color:#666666;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:101pt;border-top-style:solid;border-top-width:2pt;border-top-color:#666666;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:93pt;border-top-style:solid;border-top-width:2pt;border-top-color:#666666;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:98pt;border-top-style:solid;border-top-width:2pt;border-top-color:#666666;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
      </tr>
      <tr style="height:73pt">
         <td style="width:128pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:105pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:137pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:58pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:101pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:93pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:98pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
      </tr>
      <tr style="height:65pt">
         <td style="width:128pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:105pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:137pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:58pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:101pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:93pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:98pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
      </tr>
      <tr style="height:65pt">
         <td style="width:128pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:105pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:137pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:58pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:101pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:93pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
         <td style="width:98pt;border-top-style:solid;border-top-width:1pt;border-top-color:#999999;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </td>
      </tr>
   </tbody>
</table>
<p class="s11" style="padding-top: 4pt;text-indent: 0pt;line-height: 12pt;text-align: right;">№ 108 el</p>
<p class="s12" style="padding-left: 431pt;text-indent: 0pt;line-height: 15pt;text-align: right;">0 700 47 444 – национален телефон</p>
<p style="padding-left: 431pt;text-indent: 0pt;text-align: right;">www.ritam4tb.com / contact@ritam-bg.com</p>
<p class="s16" style="padding-left: 431pt;text-indent: 0pt;text-align: right;">6000 Стара Загора, ул. Св. Княз Борис № 93, ет. 9 1000 София, ул. Иван Денкоглу № 34, ет. 2</p>

`;

const RitmPreview = ({ data, watermark, logo, ...props }) => {
   const getMeasurePointsHtml = () => {
      let tableRowsHtml = '';

      tableRowsHtml = data?.points && data.points.length > 0
        ? `
          ${
            data.points.map((p) => `
               <tr style="height:64pt">
                 <td style="width:128pt;border-top-style:solid;border-top-width:2pt;border-top-color:#666666;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
                    <p style="text-indent: 0pt;text-align: left;"><br></p>
                 </td>
                 <td style="width:105pt;border-top-style:solid;border-top-width:2pt;border-top-color:#666666;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
                    <p style="text-indent: 0pt;text-align: left;">
                     ${p.itn}
                    </p>
                 </td>
                 <td style="width:137pt;border-top-style:solid;border-top-width:2pt;border-top-color:#666666;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
                    <p style="text-indent: 0pt;text-align: left;">
                     ${[p.city, p.street, p.zip].filter(Boolean).join(', ')}
                    </p>
                 </td>
                 <td style="width:58pt;border-top-style:solid;border-top-width:2pt;border-top-color:#666666;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
                    <p style="text-indent: 0pt;text-align: left;">
                     ${p.operator ? OPERATOR_OPTIONS_MAP[p.operator] : ''}
                    </p>
                 </td>
                 <td style="width:101pt;border-top-style:solid;border-top-width:2pt;border-top-color:#666666;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
                    <p style="text-indent: 0pt;text-align: left;"><br></p>
                 </td>
                 <td style="width:93pt;border-top-style:solid;border-top-width:2pt;border-top-color:#666666;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
                    <p style="text-indent: 0pt;text-align: left;"><br></p>
                 </td>
                 <td style="width:98pt;border-top-style:solid;border-top-width:2pt;border-top-color:#666666;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
                    <p style="text-indent: 0pt;text-align: left;"><br></p>
                 </td>
               </tr>
           `)
          }
        `
        : '';

        if (!data.points || data?.points?.length < 4) {
         const limit = data?.points?.length ? 4 - data.points.length : 4;
         for (let index = 0; index < limit; index++) {
            tableRowsHtml += `
            <tr style="height:64pt">
            <td style="width:128pt;border-top-style:solid;border-top-width:2pt;border-top-color:#666666;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
               <p style="text-indent: 0pt;text-align: left;"><br></p>
            </td>
            <td style="width:105pt;border-top-style:solid;border-top-width:2pt;border-top-color:#666666;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
               <p style="text-indent: 0pt;text-align: left;"><br></p>
            </td>
            <td style="width:137pt;border-top-style:solid;border-top-width:2pt;border-top-color:#666666;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
               <p style="text-indent: 0pt;text-align: left;"><br></p>
            </td>
            <td style="width:58pt;border-top-style:solid;border-top-width:2pt;border-top-color:#666666;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
               <p style="text-indent: 0pt;text-align: left;"><br></p>
            </td>
            <td style="width:101pt;border-top-style:solid;border-top-width:2pt;border-top-color:#666666;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
               <p style="text-indent: 0pt;text-align: left;"><br></p>
            </td>
            <td style="width:93pt;border-top-style:solid;border-top-width:2pt;border-top-color:#666666;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
               <p style="text-indent: 0pt;text-align: left;"><br></p>
            </td>
            <td style="width:98pt;border-top-style:solid;border-top-width:2pt;border-top-color:#666666;border-left-style:solid;border-left-width:1pt;border-left-color:#999999;border-bottom-style:solid;border-bottom-width:1pt;border-bottom-color:#999999;border-right-style:solid;border-right-width:1pt;border-right-color:#999999">
               <p style="text-indent: 0pt;text-align: left;"><br></p>
            </td>
         </tr>
            `
         }
        }
 
       return tableRowsHtml;
    };
  return (
      <StyledWrapper
        id='htmlPdfContract'
        {...props}
        style={{
         fontFamily: 'Roboto-Regular',
         letterSpacing: '1px'
        }}
        // dangerouslySetInnerHTML={{ __html: getFormattedData(ritmHtml, data, logo, getMeasurePointsHtml)}}
      >
         <StyledPageWrapper className="new-pdf-page" dangerouslySetInnerHTML={{ __html: getFormattedData(ritmHtml, data, logo, getMeasurePointsHtml) }} />
         <StyledPageWrapper className="new-pdf-page" dangerouslySetInnerHTML={{ __html: getFormattedData(table1, data, logo, getMeasurePointsHtml) }} />
         <StyledPageWrapper className="new-pdf-page" dangerouslySetInnerHTML={{ __html: getFormattedData(table2, data, logo, getMeasurePointsHtml) }} />
      </StyledWrapper>
    )
};

export default RitmPreview;