import { styled } from "@mui/material";
import React from "react"
import SvgLogoIcon from "../../../images/zoom-in.inline.svg"

const StyledIcon = styled(SvgLogoIcon)``;

const ZoomIn = ({ width = "24", height = "24", ...props }) => {
  return (
    <>
      <StyledIcon width={width} height={height} {...props} />
    </>
  )
}

export default ZoomIn
