import { styled, useMediaQuery, useTheme } from '@material-ui/core';
import { StepLabel, Stepper, Step, Typography, Box } from '@mui/material';
import cssStyled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import CustomStep from './CustomStep';
import CustomConnector from './CustomConnector';

const StyledWrapper = cssStyled(Box)`
  display: flex;
  flex-direction: column;

  svg {
    margin-left: 0;
    margin-bottom: 0;
  }

  .MuiStepIcon-root.MuiStepIcon-active {
    color: #E6401E;
  }
`;

const StyledStepper = styled(Stepper)({
  backgroundColor: 'inherit',
});


function SignOfferStepper({ active, completedSteps, steps, handleStep }) {
  const theme = useTheme();
  const xsBreakpoint = useMediaQuery(theme.breakpoints.down('xs'));


  return (
    <StyledWrapper sx={{ my: { xs: 2, sm: 0 } }}>
      <StyledStepper
        orientation={!xsBreakpoint ? 'vertical' : 'horizontal'}
        activeStep={active}
        connector={<CustomConnector />}
      >
        {steps.map((s, i) => (
          <Step
            key={s.label}
            completed={i === active ? false : completedSteps[i].completed}
            color="inherit"
          >
            <StepLabel componentsProps={{
              label: {
                sx: {
                  display: {
                    xs: 'none',
                    sm: 'flex',
                  },
                  justifyContent: {
                    xs: i === 0
                      ? 'start'
                      : i === 2
                        ? 'end'
                        : 'center',
                    sm: 'start',
                  }
                }
              }
            }}
            StepIconComponent={CustomStep}
            StepIconProps={{
              number: i + 1,
              active: i === active,
            }}
            >
              {s.label}
            </StepLabel>
          </Step>
        ))}
      </StyledStepper>
      {xsBreakpoint && <Typography
        display="flex"
        py={1}
        fontWeight={theme.typography.fontWeightMedium}
        justifyContent={
          active === 0
            ? 'start'
            : active === 2
              ? 'end'
              : 'center'
        }
      >
        {steps[active].label}
      </Typography>}
    </StyledWrapper>
  );
}

SignOfferStepper.propTypes = {
  active: PropTypes.number.isRequired,
  completedSteps: PropTypes.array.isRequired,
  steps: PropTypes.array.isRequired,
  handleStep: PropTypes.func.isRequired,
};

export default SignOfferStepper;
