import React, { useCallback } from 'react';
import { styled } from '@mui/material';
import dayjs from 'dayjs';
import { getFormattedData } from './utils/helpers';
import { OPERATOR_OPTIONS_MAP } from '../../../../../helpers/constants';

const StyledWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
`;

const StyledPageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  font-family: 'Roboto-Regular';
  letter-spacing: 1px;
  padding-left: 3rem;
  padding-right: 3rem;
  
  span {
   all: inherit !important;
  }
  
  p, ol {
   font-size: 22px !important;
   line-height: 22px !important;
   color: black;
  }
  
  b {
   font-size: 24px;
  }
  
  ol {
   padding-left: 15px;
  }
  
  ol > li {
   list-style: none;
  }

  .list-with-no-style > li {
   list-style: none;
  }
`;

const StyledDataElement = styled('strong')`
  border-bottom: 1px dotted black;
  white-space: pre;
`;

const StyledDocumentPage = styled('div')`
  padding: 2rem;
  background-color: white;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
  position: relative;
`;

const StyledDocumentTitle = styled('b')`
  display: block;
  width: 100%;
  text-align: center;
`;

const StyledColumn = styled('td')`
  width: 50%;
  border: none;
`;

const StyledDiagonalText = styled('span')`
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  transform-origin: top left;
  background-color: transparent;
  color: rgb(0 0 0 / 9%);
  padding: 10px;
  font-size: 180px; 
`;

const StyledPageNumber = styled('h6')`
  text-align: end;
`;

const tokiHtml = `
{LOGO_HEADER}
<p style="text-indent: 0pt;text-align: left;"><br></p>
<p class="s1" style="padding-top: 3pt;padding-left: 6pt;text-indent: 0pt;text-align: justify;">Номер на договора: [●] Дата на създаване на договора: {DATE}</p>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<b style="padding-left: 127pt;text-indent: 0pt;text-align: center;">Договор за комбинирани услуги при общи условия</b>
<br />
<p style="padding-top: 7pt;padding-left: 6pt;text-indent: 0pt;text-align: justify;">Настоящият Договор се сключи между:</p>
<p class="s4" style="padding-top: 8pt;padding-left: 6pt;text-indent: 0pt;text-align: justify;">Продавач: "КЕР ТОКИ ПАУЪР"АД,ЕИК: 206117083,</p>
<p style="padding-top: 7pt;padding-left: 6pt;text-indent: 0pt;line-height: 108%;text-align: justify;">представлявано от: Юри Богомилов Катанов и Георги Стефанов Пулев, седалище и адрес на управление: гр. София, бул. "Цар Освободител №14", адрес за кореспонденция: гр. София, бул. "Христофор Колумб" №41, ет. 6, имейл: office@toki.bg,</p>
<p style="padding-top: 6pt;padding-left: 5pt;text-indent: 0pt;text-align: justify;">телефон: 0700 13961, 02/9071620,</p>
<p style="padding-top: 8pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">и</p>
<p class="s11" style="padding-top: 7pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">Купувач: {COMPANY}, ЕИК: {EIK},</p>
<p style="padding-top: 7pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">представлявано от: {NAME},</p>
<p style="padding-top: 6pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">седалище и адрес на управление: {ADDRESS}</p>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<p style="padding-left: 6pt;text-indent: 0pt;text-align: left;"><p style="color: black; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt;" >имейл: {EMAIL},</p>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">телефон: {PHONE},</p>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<p style="padding-left: 6pt;text-indent: 36pt;line-height: 110%;text-align: justify;">На основание чл. 11, т. 4 и/или т. 10 във връзка с чл. 20 и/или чл. 28, ал. 1 от Правила за търговия с електрическа енергия („ПТЕЕ“) и при прилагане на Общите условия на Договора за комбинирани услуги при крайни клиенти със стандартизиран товаров профил и на Договора за продажба на електрическа енергия на крайни клиенти с почасово измерване и участието им в стандартната балансираща група на  „КЕР ТОКИ ПАУЪР“ АД (наричани по–долу само „Общите условия“), установени от Продавача и приети от Купувача съгласно разпоредбата на чл. 298 от Търговския закон.</p>
<p style="text-indent: 0pt;text-align: left;"><br></p>
`;

const page2 = `
<ol id="l1">
   <li data-list-text="1.">
      <p style="text-indent: 40pt;padding-left: 6pt;">1. Продавачът продава на Купувача активна електрическа енергия, а Купувачът купува и се задължава да заплаща количеството електрическа енергия, измерено чрез средствата за търговско измерване, при условията на този Договор и Общите условия за следните Обекти на Купувача, посочени по-долу или в Приложение № 1, представляващо неразделна част от настоящия Договор:</p>
      <p style="text-indent: 0pt;text-align: left;"><br></p>
      <table style="border-collapse:collapse;margin-left:12.575pt" cellspacing="0">
         <tbody>
            <tr style="height:59pt">
               <td style="width:113pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                  <p class="s16" style="padding-top: 6pt;text-indent: 0pt;text-align: left;">Обект</p>
               </td>
               <td style="width:113pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                  <p class="s17" style="padding-top: 6pt;text-indent: 0pt;text-align: left;">Идентификационен код</p>
               </td>
               <td style="width:129pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                  <p class="s16" style="padding-top: 5pt;text-indent: 0pt;line-height: 113%;">Присъединен към електро разпределителната мрежа на</p>
               </td>
               <td style="width:106pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                  <p class="s17" style="padding-top: 5pt;text-indent: 0pt;text-align: left;">Адрес на обекта </p>
               </td>
            </tr>
            {MEASURE_POINTS}
            <tr style="height:38pt">
               <td style="width:113pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                  <p class="s16" style="padding-top: 6pt;text-indent: 0pt;text-align: left;">[●]</p>
               </td>
               <td style="width:113pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                  <p class="s16" style="padding-top: 6pt;text-indent: 0pt;text-align: left;">[●]</p>
               </td>
               <td style="width:129pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                  <p class="s16" style="padding-top: 6pt;text-indent: 0pt;text-align: left;">[●]</p>
               </td>
               <td style="width:106pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                  <p class="s16" style="padding-top: 6pt;text-indent: 0pt;text-align: left;">[●]</p>
               </td>
            </tr>
         </tbody>
      </table>
      <p style="text-indent: 0pt;text-align: left;"><br></p>
   </li>
   <li data-list-text="2.">
      <p style="text-indent: 40pt;padding-left: 6pt;">2. Купувачът прехвърля на Продавача отговорността за балансиране по отношение на изброения/те Обект/ти по т.   1. и съответно става член на стандартната балансираща група на Продавача при спазване на Общите условия.</p>
   </li>
   <br/>
   <li data-list-text="3.">
      <p style="padding-top: 1pt;padding-left: 6pt;text-indent: 0pt;text-align: justify;text-indent: 40pt;">3. Страните се договарят, че цената на продаваната електрическа енергия по този Договор се формира   от следните ценови компонента/ти:
      <ul class="list-with-no-style" id="l2">
         <li data-list-text="-">
            <p style="padding-top: 8pt;padding-left: 6pt;text-indent: 56pt;text-align: justify;">- Единична  цена  за  1  MWh  потребена  електрическа  енергия  равна  на среднопретеглената цена на сегмент „Ден напред“ на „Българска независима енергийна борса“ ЕАД (лева/MWh) за съответния период на сетълмент (без ДДС) плюс</p>
            <p style="text-indent: 0pt;text-align: left;"><br></p>
         </li>
         <li data-list-text="-">
            <p style="padding-left: 6pt;text-indent: 56pt;text-align: justify;">- Търговска надбавка в размер на [●] лв./MWh ([●] лева на мегават час) (без ДДС), включваща балансиране и администриране доставката на електрическата енергия.</p>
         </li>
      </ul>
      <li data-list-text="3.1.">
         <p style="padding-top: 6pt;padding-left: 6pt;text-indent: 35pt;line-height: 114%;text-align: justify;">3.1. В случай че Обект/ите на Купувача са с почасово измерване, Купувачът следва да подпише и предостави декларация по образеца на съответния Мрежови оператор, с която да декларира съгласието си всички суми, които Купувачът дължи за предоставените му услуги от съответния Мрежови оператор, да се заплащат от Продавача за сметка на Купувача. В случай че Купувачът не е предоставил декларацията по образец относно Обект/ите с почасово измерване, за тях ще се прилагат разпоредбите на Глава 3 от Общите условия. Тази разпоредба не се прилага, ако по отношение на Обект/ите на Купувача се прилагат СТП.</p>
      </li>
      <li data-list-text="3.2.">
         <p style="padding-top: 6pt;padding-left: 6pt;text-indent: 35pt;line-height: 108%;text-align: justify;">3.2. Във фактурата Продавачът отделно включва освен дължимата месечна сума за електрическа енергия в съответствие с т. 3, също и дължимите от Купувача като краен клиент на електрическа енергия - акциз, цена „Задължение към обществото“ и цени за мрежовите услуги, определени от КЕВР, данък добавена стойност, както и всички други такси/данъци и др., съгласно закона и/или актове на административни органи.</p>
      </li>
      <li data-list-text="3.3.">
         <p style="padding-top: 6pt;padding-left: 6pt;text-indent: 35pt;line-height: 111%;text-align: justify;">3.3. Купувачът заплаща фактурираните суми по т. 3, 3.1. и 3.2 в 15 (петнадесет) дневен   срок  от  получаването  на издадената  фактура  от  Продавача  на  Купувача.  Купувачът заплаща  дължимите   суми по  някой от определените  начини  в  Общите   условия. </p>
      </li>
   </li>
   <br/>
   <li  data-list-text="4.">
      <p style="text-indent: 40pt;">4. Срок на Договора:</p>
      <ul class="list-with-no-style" id="l4">
         <li data-list-text="-">
            <p style="padding-top: 7pt;padding-left: 6pt;text-align: left;text-indent: 56pt;">- 1  (една)  година,  считано  от  датата  на влизане в  сила  на Договора;</p>
         </li>
         <li data-list-text="-">
            <p style="padding-top: 7pt;padding-left: 6pt;text-align: justify;text-indent: 56pt;">- Договорът може да бъде прекратен едностранно от всяка от Страните с отправянето на едномесечно писмено предизвестие до другата Страна, като прекратяването настъпва на 1-во (първо) число на календарния месец, следващ непосредствено месеца, в който  изтича предизвестието. </p>
         </li>
      </ul>
      <p style="text-indent: 0pt;text-align: left;"><br></p>
   </li>
   <li data-list-text="5.">
     <p style="text-indent: 40pt;">5. Договорът  влиза  в  сила  от 00:00 ч. на  първо  число на  месеца, следващ месеца,  през който операторът на електроразпределителната  мрежа  е  извършил  процедурите  по смяна  на  доставчика  и координатора на  балансираща   група,  и  съответно Купувачът е регистриран   като  член на стандартната балансираща група на Продавача, съгласно и в  съответствие с  ПТЕЕ, с изключение на т. 6, която  влиза  в  сила  незабавно  при  подписването  му.</p>
   </li>
   <br/>
   <li data-list-text="6.">
      <p style="text-indent: 40pt;">6. С настоящия Договор Купувачът изрично упълномощава Продавача от името и за сметка на Купувача:</p>
      <p style="padding-top: 7pt;padding-left: 6pt;text-indent: 36pt;line-height: 110%;text-align: justify;">а) да сключи договори с оператора на електроразпределителната мрежа и с  доставчика  от последна инстанция,  както  и да  подаде  заявление  за  първоначалната регистрация на пазара на електрическа енергия или заявление за  смяна  на доставчика/координатора на  балансираща   група; </p>
      <p style="padding-top: 6pt;padding-left: 6pt;text-indent: 36pt;line-height: 112%;text-align: justify;">б) да го представлява пред оператора на електроразпределителната мрежа  във връзка с  промяна на стандартизирания товаров профил и получаване на данни относно потреблението на  електрическа  енергия;</p>
      <p style="padding-top: 6pt;padding-left: 6pt;text-indent: 36pt;line-height: 108%;text-align: justify;">в) Купувачът изрично  упълномощава Продавача да подпише от негово   име и   да предаде в  съответния  мрежови  оператор  декларация  (по  образец  на  съответния  оператор),  че  електрическите  инсталации в Обектите  по   т.  1 и  съоръженията   за  присъединяване,  разположени  след  нормативно   установената  граница  на  собственост  на  съоръженията,   са    изпълнени   съгласно  действащото  законодателство  и съответстват  на  приложимите   технически   норми  относно такива съоръжения и инсталации,  включително   техническите   норми   за устройство  и  експлоатация  на електроцентрали и   мрежи,    както   и  на   изискванията  за  безопасни и здравословни  условия  на  труд. </p>
   </li>
   <br/>
   <li data-list-text="7.">
     <p style="text-indent: 40pt;">7. С подписването на този Договор Купувачът изрично заявява, че приема Общите условия, както са публикувани на www.toki.bg.</p>
   </li>
   <br/>
   <li data-list-text="8.">
     <p style="text-indent: 40pt;">8. За всички неуредени случаи в Договора се прилагат Общите условия, които съставляват неразделна част от този Договор.</p>
   </li>
</ol>
<p style="text-indent: 0pt;text-align: left;">  </p>
<p></p>
<table style="border-collapse:collapse;margin-left:8.9pt" cellspacing="0">
   <tbody>
      <tr style="height:20pt">
         <td style="width:198pt">
            <p class="s17" style="padding-left: 2pt;text-indent: 0pt;line-height: 12pt;text-align: left;">Продавач:</p>
         </td>
         <td style="width:248pt">
            <p class="s29" style="padding-left: 37pt;text-indent: 0pt;line-height: 12pt;text-align: left;">Купувач:</p>
         </td>
      </tr>
      <tr style="height:70pt">
         <td style="width:198pt;border-bottom-style:solid;border-bottom-width:1pt">
            <p class="s17" style="padding-top: 6pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">Подпис: .......................................... </p>
            <p class="s30" style="padding-top: 8pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">Юри Богомилов Катанов</p>
            <p class="s17" style="padding-top: 8pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">„КЕР ТОКИ ПАУЪР“ АД </p>
         </td>
         <td style="width:248pt;border-bottom-style:solid;border-bottom-width:1pt">
            <p class="s17" style="padding-top: 6pt;padding-left: 37pt;text-indent: 0pt;text-align: left;">Подпис: ......................................... </p>
            <p class="s16" style="padding-top: 8pt;padding-left: 37pt;text-indent: 0pt;text-align: left;">{NAME}</p>
            <p class="s16" style="padding-top: 8pt;padding-left: 37pt;text-indent: 0pt;text-align: left;">{COMPANY}</p>
         </td>
      </tr>
   </tbody>
</table>
<p style="text-indent: 0pt;text-align: left;"><br></p>
<p class="s4" style="padding-top: 3pt;padding-left: 11pt;text-indent: 0pt;text-align: left;">Подпис: .......................................... </p>
<p class="s9" style="padding-top: 7pt;padding-left: 11pt;text-indent: 0pt;text-align: left;">Героги Стефанов Пулев </p>
<p style="text-indent: 0pt;text-align: left;"></p>
<p></p>
<p class="s4" style="padding-top: 8pt;padding-left: 11pt;text-indent: 0pt;text-align: left;">„КЕР ТОКИ ПАУЪР“ АД</p>
`;

const TokiPreview = ({ data, watermark, logo, ...props }) => {
   const getMeasurePointsHtml = () => {
     const tableRowsHtml = data.points && data.points.length > 0
       ? `
         ${
            data.points.map((p) => `
              <tr>
                <td style="border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt"
                >
                </td>
                <td style="border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt"
                >
                  <p>
                    ${p.itn}
                  </p>
                </td>
                <td style="border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt"
                >
                  <p>
                  ${p.operator ? OPERATOR_OPTIONS_MAP[p.operator] : ''}
                  </p>
                </td>
                <td style="border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt"
                >
                  <p>
                    ${[p.city, p.street, p.zip].filter(Boolean).join(', ')}
                  </p>
                </td>
              </tr>
            `)
         }
       `
       : ''

      return tableRowsHtml;
   };

    return (
      <StyledWrapper
        id='htmlPdfContract'
        {...props}
        style={{
         fontFamily: 'Roboto-Regular',
         letterSpacing:'1px',
        }}
        // dangerouslySetInnerHTML={{ __html: getFormattedData(tokiHtml, data, logo, getMeasurePointsHtml)}}
      >
         <StyledPageWrapper className="new-pdf-page" dangerouslySetInnerHTML={{ __html: getFormattedData(tokiHtml, data, logo, getMeasurePointsHtml)}} />
         <StyledPageWrapper className="new-pdf-page" dangerouslySetInnerHTML={{ __html: getFormattedData(page2, data, logo, getMeasurePointsHtml)}} />
      </StyledWrapper>
    )
  };
  
  export default TokiPreview;