import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { getCompany, validateEIK } from '../../../helpers/getEIK';

const EikField = ({ onRetrieveEikData, value, loading, setLoading, ...props }) => {
  const [prevCompanyData, setPrevCompanyData] = useState({ UIC: undefined });
  const [lastRetrievedEik, setLastRetrievedEik] = useState('');

  const handleNewCompanyData = useCallback((newCompanyData) => {
    setPrevCompanyData(
      (prev) => prev.UIC !== newCompanyData.UIC
        ? newCompanyData
        : prev
    );
  }, [setPrevCompanyData]);

  const callParentMethod = useCallback((newCompanyData) => {
    if (prevCompanyData.UIC !== newCompanyData.UIC) {
      if (onRetrieveEikData) {
        onRetrieveEikData(newCompanyData);
      }
    }
  }, [prevCompanyData, onRetrieveEikData]);

  const getCompanyDetailsAsync = useCallback(async (eik) => {
    try {
      setLoading(true);

      const companyData = await getCompany(eik);

      callParentMethod(companyData);

      handleNewCompanyData(companyData);
    } catch (error) {
      console.log('Error retrieving company data', error);
    }

    setLoading(false);
    setLastRetrievedEik(eik);
  }, [
    callParentMethod,
    handleNewCompanyData,
    getCompany,
    setLoading
  ]);

  useEffect(() => {
    if (loading) {
      return;
    }

    const isEikValid = value && validateEIK(value);
    const isEikDifferent = value !== lastRetrievedEik;
    const isEikForDifferentCompany = prevCompanyData.UIC ? value !== prevCompanyData.UIC : true;

    if (isEikValid && isEikDifferent && isEikForDifferentCompany && !loading) {
      getCompanyDetailsAsync(value);
    }
  }, [value, prevCompanyData, loading, validateEIK, getCompanyDetailsAsync, lastRetrievedEik]);

  return (
    <TextField
      id="eik"
      name="eik"
      label="ЕИК"
      variant="outlined"
      style={{ margin: 8 }}

      {...props}

      value={value}
    />
  )
};

EikField.propTypes = {
  value: PropTypes.string.isRequired,
  onRetrieveEikData: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default EikField;