import { Breadcrumbs, Typography, useMediaQuery } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'gatsby';
import React from 'react';
import { useTheme } from '@material-ui/core';

function SignOfferBreadcrumb({ offerName }) {
  const theme = useTheme();
  const smallBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon sx={{ margin: { xs: 0 } }} fontSize="small" />}

      aria-label="breadcrumb"
    >
      {!smallBreakpoint && <Typography color="text.primary">Начало</Typography>}

      <Typography color="text.primary">Всички оферти</Typography>

      <Typography color="text.primary">{offerName}</Typography>

      <Typography fontWeight="600" color="text.primary">{smallBreakpoint ? 'Договор' : 'Заявяване на договор'}</Typography>
    </Breadcrumbs>
  )
}

export default SignOfferBreadcrumb