import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material';
import CompletedStepIcon from '../../atoms/icons/CompletedStep';

const StyledStep = styled('span')(({theme, active}) => `
  border: 4px solid ${active ? theme.palette.secondary.main : theme.palette.grey[600]};
  border-radius: 25px;
  width: 32px;
  color: ${active ? theme.palette.secondary.main : theme.palette.grey[600]};
  background-color: ${active ? 'white' : 'inherit'};
  font-weight: bold;
  text-align: center;
  padding: 0;
`);

function CustomStep({ completed, active, number, ...rest }) {
  return completed
    ? (
      <CompletedStepIcon />
    )
    : (
      <StyledStep active={active} {...rest}>{number}</StyledStep>
    )
};

export default CustomStep;