import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout/layout';
import SignOfferBreadcrumb from '../components/signOffer/SignOfferBreadcrumb';
import SignOfferForm from '../components/signOffer/SignOfferForm';
import Seo from "../components/seo";

function SignOfferTemplate({ data, location, ...props}) {
  const theme = useTheme();
  const xsBreakpoint = useMediaQuery(theme.breakpoints.down('xs'));

  const params = new URLSearchParams(location.search);
  const operator = params.get('operator');
  const { offerName, contract, distributor, videoGuideLink } = data.contentfulDistributorOffer;
  const distributorFullLogo = distributor?.fullLogo ?
    {
      src: distributor.fullLogo.file.url,
      alt: distributor.fullLogo.title,
    }
    : undefined;

  return (
    <>
      <Layout
        hideFooter
        hideForm
        mainSectionProps={{
          className: 'background-gray',
          style: { minHeight: '100vh' },
        }}
      >
        <Seo />
        <div className="container separator--section">
          <Box className="py-3" sx={{
            display: 'flex',
            justifyContent: {
              xs: 'center',
              sm: 'start',
            },
            bgcolor: xsBreakpoint ? 'white' : 'inherit'
          }}
          >
            <SignOfferBreadcrumb offerName={offerName} />
          </Box>

          <div>
            <SignOfferForm
              operator={operator}
              contract={contract}
              distributor={distributor}
              offerName={offerName}
              logo={distributorFullLogo}
              videoGuideLink={videoGuideLink?.trim()}
            />
          </div>
        </div>
      </Layout>
    </>
  );
}

export default SignOfferTemplate;


export const pageQuery = graphql`
  query($id: String!) {
    contentfulDistributorOffer(id: { eq: $id }, node_locale: { eq: "bg" }) {
      offerName
      contract {
        url
        publicUrl
        file {
          fileName
        }
      }
      videoGuideLink
      distributor {
        title
        fullLogo {
          title
          file {
            url
          }
        }
      }
    }
  }
`