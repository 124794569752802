import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { FieldArray, Formik } from 'formik';
import React, { useCallback } from 'react';
import FormSubmitButton from '../FormSubmitButton';
import AddPointButton from './AddPointButton';
import MeasurePointBox from './MeasurePointBox';
import { CollectionsBookmarkOutlined } from '@mui/icons-material';
import BackButton from '../BackButton';
import { measurePointsValidationSchema } from '../validation/schemas';
import { fireGAEvent } from "../../../../helpers/ga";

const setInitialValues = (points, street, zip, city) => {
  if (!points || !points.length) {
    return points;
  }

  const measurePoints = [...points].map(p => {
    if (p.check) {
      p = {
        ...p,
        street,
        city,
        zip,
      }
    }

    return p;
  });


  return { points: measurePoints };
}

function MeasurePointsFields({ initialValues: { points, street, zip, city }, submitBtnProps, onSave, back, currentOperator }) {
  const theme = useTheme();
  const isBelowMdBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  const isButtonDisabled = (values) => {
    const { points } = values

    let isDisabled = true;

    if (points && points.length > 0) {
      isDisabled = points.some(p => {
        const { check, itn, file, fileName, ...rest } = p;

        const itnOrFile = itn || fileName;

        if (check) {
          return Object.values({ itnOrFile, operator: p.operator }).some(
            (v) => v.length === 0
          );
        } else {
          const restValues = Object.values(rest);
          return itnOrFile.length === 0 || restValues.some(v => v.length < 3);
        }
      })
    }

    return isDisabled
  }

  const resetPointAddress = (pointIndex, setValue) => {
    setValue(`points.${pointIndex}.city`, city);
    setValue(`points.${pointIndex}.street`, street);
    setValue(`points.${pointIndex}.zip`, zip);
  }

  const prependPoint = (e, values, setValues) => {
    let points = [...values.points];
    points.push({
      city: city,
      zip: zip,
      street: street,
      itn: '',
      file: '',
      operator: currentOperator || 'cez',
      check: true,
      fileName: '',
    });

    setValues({ ...values, points });
  };

  const removePoint = (e, index, values, setValues) => {
    let points = [...values.points];
    points = [...points.slice(0, index), ...points.slice(index + 1)];

    setValues({ ...values, points });
  };

  const fileName = (fullPath) => {
    let filename = fullPath;
    const startIndex =
      fullPath.indexOf('\\') >= 0
        ? fullPath.lastIndexOf('\\')
        : fullPath.lastIndexOf('/');
    filename = fullPath.substring(startIndex);
    if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
      filename = filename.substring(1);
    }

    return filename;
  };

  return (
    <Formik
      initialValues={setInitialValues(points, street, zip, city)}
      onSubmit={(values) => {
         fireGAEvent("sign_contract_second_step", {
           ...values,
         });

        onSave(values);
      }}
      validationSchema={measurePointsValidationSchema}
      enableReinitialize
    >
      {({
        setFieldValue,
        errors,
        values,
        touched,
        setValues,
        handleSubmit,
        ...formikHelpers
      }) => {
        return (
          values.points.length > 0 && (
            <div>
              <FieldArray
                name="points"
                render={(arrayHelpers) =>
                  values.points.map((point, index) => {
                    const pointErrors =
                      (errors.points?.length && errors.points[index]) || {};
                    const pointTouched =
                      (touched.points?.length && touched.points[index]) || {};

                    return (
                      <div key={index} style={{ padding: '0 2.4rem' }}>
                        <MeasurePointBox
                          removePoint={(e) =>
                            removePoint(e, index, values, setValues)
                          }
                          addPoint={(e) => prependPoint(e, values, setValues)}
                          index={index}
                          pointData={point}
                          setFieldValue={setFieldValue}
                          pointTouched={pointTouched}
                          pointErrors={pointErrors}
                          formikHelpers={{
                            values,
                            handleSubmit,
                            ...formikHelpers,
                          }}
                          fileName={fileName}
                          resetPointAddress={() => resetPointAddress(index, setFieldValue)}
                        />
                      </div>
                      
                    );
                  })
                }
              />


              <Box
                className="row"
                style={{ padding: '0 2.4rem' }}
                sx={{
                  justifyContent: {
                    xs: 'center',
                    lg: 'end'
                  }
                }}
              >
                <Box
                  className="col-md-6 d-flex"
                  sx={{
                    justifyContent: {
                      xs: 'center',
                      lg: 'end'
                    }
                  }}
                >
                  <AddPointButton prependPoint={(e) => prependPoint(e, values, setValues)} />
                </Box>
              </Box>

              <hr />

              <Box
                className="row justify-content-end"
                style={{ padding: '0 2.4rem 2.4rem 2.4rem' }}
                sx={{
                  gap: {
                    xs: '1rem',
                    md: '0rem',
                  }
                }}
              >
                <Box
                  className="col-md-3 d-flex"
                  sx={{
                    justifyContent: {
                      xs: 'center',
                      lg: 'end'
                    }
                  }}
                >
                  <BackButton
                    variant="outlined"
                    style={{
                      color: theme.palette.secondary.main,
                      borderColor: theme.palette.secondary.main,
                    }}
                    onClick={() => back()}
                  >
                    Назад
                  </BackButton>
                </Box>
                <div className="col-md-3">
                  <FormSubmitButton
                    style={{
                      backgroundColor: theme.palette.secondary.main,
                    }}
                    endIcon={<NavigateNextIcon />}
                    onClick={handleSubmit}
                    disabled={isButtonDisabled(values)}
                  >
                    Продължи
                  </FormSubmitButton>
                </div>
              </Box>
            </div>
          )
        );
      }}
    </Formik>
  );
}

export default MeasurePointsFields;
