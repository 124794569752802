import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import MeasurePointsFields from "./MeasurePointsFormFields";

function MeasurePointsForm({ initialValues: { points, city, zip, street }, next, back, onSave, currentStep, currentOperator }) {
  const handleSave = (values) => {
    onSave(values, currentStep);

    if (next) {
      next();
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div style={{ padding: '2.4rem 2.4rem 0 2.4rem' }}>
            <Typography variant="h4" mb={3}>Данни за обекта</Typography>

            <p className="small-text">
              Въведете идентификация на обекта (точка на измерване)
            </p>
        </div>

        <MeasurePointsFields
          initialValues={{ points, city, zip, street }}
          onSave={handleSave}
          back={back}
          currentOperator={currentOperator}
        />
      </div>
    </div>
  );
}

MeasurePointsForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  next: PropTypes.func,
  back: PropTypes.func,
  currentOperator: PropTypes.string,
};

export default MeasurePointsForm;
