import { FormControlLabel, Radio, RadioGroup, radioClasses, styled } from '@mui/material';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

const StyledWrapper = styled('div')`
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
`;

const StyledRadioGroup = styled(RadioGroup)`
  svg {
    color: #e6401e;
  }

  & .${radioClasses.root} {
    padding: 9px !important;
  }
`;

function SignTypeForm({ initialValues, onChange, ...props }) {
  return (
    <StyledWrapper {...props}>
      <p className="py-2" style={{ margin: 0 }}>
        Съгласен съм с условията на договора и искам да го подпиша:
      </p>

      <StyledRadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        value={initialValues.sign}
        onChange={onChange}
        className="mb-2"
      >
        <FormControlLabel
          value="electronic"
          control={<Radio />}
          label="С електронен подпис"
        />
        <FormControlLabel
          value="handwritten"
          control={<Radio />}
          label="Собственоръчно"
        />
      </StyledRadioGroup>
    </StyledWrapper>
  );
}

SignTypeForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SignTypeForm;
